import axios from "axios";
import qs from "qs";
import * as config from "./config";
import { Base64 } from "js-base64";
import Helper from "./../extra/helper";

async function apicall(method, url, data) {
  let token = Helper.getParamUrl();
  const res = await axios({
    method: method,
    url: `${config.server_product}${url}`,
    data: { data: Base64.encode(data) },
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  });
  return res;
}

async function apicall_upload_G2(method, url, data, configCustom) {
  let token = Helper.getParamUrl();
  const res = await axios({
    method: method,
    url: `${config.G2Domain}${url}`,
    data: data,
    headers: {
      Authorization: `bearer ${token}`,
    },
    ...configCustom,
  });
  return res;
}

export default {
  apicall,
  apicall_upload_G2
};