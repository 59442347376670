import React, { useContext } from "react";
import { AuthContext } from "./../index";
import { Button, Modal } from "antd";
import Helper from "./../../../extra/helper";
import History from "./../../../routes/history";
import cartdone from "./../../../assets/suc-khoe-vang/idone.svg";

const ModalDone = (props) => {
  const { dataCheckReDeem, ArrPackage, dataIsu } = useContext(AuthContext);

  const NewCreate = () => {
    window.location.reload();
    CustomResetLocalStorage();
    props.OpenDone();
    History.replace("/gotohome");
  };

  const CustomResetLocalStorage = () => {
    localStorage.removeItem("vatskv");
    localStorage.removeItem("listInsuredSKV");
    localStorage.removeItem("dataIsuskv");
    localStorage.removeItem("dataSearchSKV");
  };

  const netxPayment = () => {
    CustomResetLocalStorage();
    let platform = Helper.getParamUrlByKey("platform");
    if (platform === "app") {
      History.replace("/gotocart/" + props.dataOrder.orderCode);
    } else {
      window.location.replace(props.dataOrder.paymentLink);
    }
  };
  let fee = +props?.dataOrder?.fees;
  return (
    <div style={{ overflow: "hidden" }}>
      <Modal
        style={{ fontSize: 15}}
        footer={false}
        closable={false}
        open={true}
        centered={true}
      >
        <div>
          <div style={{ textAlign: "center" }}>
            <img src={cartdone} alt="home" width="25%" height="auto" />
            <div>
              <div style={{ marginTop: 15, fontWeight: 600, fontSize: 18 }}>
                Xác nhận đơn hàng thành công
              </div>
              <div style={{ marginTop: 15 }}>
                Đơn hàng{" "}
                <span style={{ fontWeight: 500 }}>Bảo hiểm Sức Khoẻ Vàng</span>{" "}
                <br />
                đã được {fee == 0 ? "thanh toán" : "thêm vào giỏ hàng"} <br />
                <span style={{ fontWeight: 500 }}>thành công</span>.
              </div>
            </div>
            <div style={{ fontWeight: 600, marginTop: "2%" }}>
              {props.createOrder?.paymentDescription}
            </div>
            {fee == 0 ? (
              <div
                style={{
                  textAlign: "center",
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => NewCreate()}
                  style={{
                    width: "47%",
                    fontSize: 12,
                    borderRadius: 7,
                    fontWeight: "bold",
                    color: "#39B44A",
                    border: "1px solid #39B44A",
                  }}
                  color="#ED5A33"
                >
                  Trở lại trang chủ
                </Button>
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => NewCreate()}
                  style={{
                    width: "47%",
                    fontSize: 12,
                    borderRadius: 7,
                    fontWeight: "bold",
                    color: "#39B44A",
                    border: "1px solid #39B44A",
                  }}
                  color="#ED5A33"
                >
                  Trở lại trang chủ
                </Button>
                <Button
                  onClick={() => netxPayment()}
                  style={{
                    width: "47%",
                    fontSize: 12,
                    background: "#39B44A",
                    borderRadius: 7,
                    fontWeight: "bold",
                    color: "#ffffff",
                  }}
                  color="#ED5A33"
                >
                  {Helper.getParamUrlByKey("platform") === "app"
                    ? "Đến giỏ hàng"
                    : "Thanh toán"}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalDone;
