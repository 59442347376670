import React, { useContext } from "react";
import { Button, Modal } from "antd";
import IWarning from "./../../../../assets/suc-khoe-vang/iwarning.svg";

const ModalWarning = (props) => {
  return (
    <div style={{ overflow: "hidden" }}>
      <Modal
        style={{ fontSize: 14 }}
        footer={false}
        closable={false}
        open={props.open}
      >
        <div>
          <div style={{ textAlign: "center" }}>
            <img src={IWarning} alt="home" width="18%" height="auto" />
            <div>
              <div style={{ marginTop: 15, textAlign: "justify" }}>
                Bạn có chắc chắn loại{" "}
                <span style={{ fontWeight: 500 }}>
                  {props.dataEditList?.values?.insuredName}
                </span>{" "}
                ra khỏi danh sách đóng phí không? <br />
                <span style={{ fontWeight: 500 }}>Lưu ý:</span> đơn bảo hiểm của{" "}
                <span style={{ fontWeight: 500 }}>
                  {props.dataEditList?.values?.insuredName}
                </span>{" "}
                sẽ không thể khôi phục sau khi thanh toán.
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: 15,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => {
                  props.handCancel();
                  props.EditList(
                    props.dataEditList.index,
                    props.dataEditList.values
                  );
                }}
                style={{
                  width: "40%",
                  fontSize: 14,
                  borderRadius: 24,
                  fontWeight: 500,
                  color: "#0B3C8A",
                  height: 46,
                  border: "1px solid #0B3C8A",
                }}
                color="#ED5A33"
              >
                Đồng ý
              </Button>
              <Button
                onClick={() => props.handCancel()}
                style={{
                  width: "40%",
                  fontSize: 15,
                  background: "#0B3C8A",
                  borderRadius: 24,
                  fontWeight: 700,
                  color: "#ffffff",
                  height: 46,
                }}
                color="#ED5A33"
              >
                Từ chối
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalWarning;
