import React, { useContext } from "react";
import { Button, Modal } from "antd";
import { AuthContext } from "./../index";
import IWarning from "./../../../../src/assets/suc-khoe-vang/iwarning.svg";

const ModalWarningAboutRepeatOrders = (props) => {
  const { goStep } = useContext(AuthContext);

  return (
    <div style={{ overflow: "hidden" }}>
      <Modal
        style={{ fontSize: 14, maxWidth: 340, margin: "0px auto" }}
        footer={false}
        closable={false}
        open={props.open}
        centered={true}
      >
          <div>
            <div style={{ textAlign: "center" }}>
              <img src={IWarning} alt="home" width="18%" height="auto" />
              <div>
                {(+props?.statusTracking === 4 ||
                  +props?.statusTracking === 6) && (
                  <div style={{ marginTop: 15, textAlign: "justify" }}>
                    Hợp đồng bảo hiểm của bạn đã{" "}
                    <b>bị huỷ do quá hạn đóng phí</b>. Vui lòng thực hiện mua
                    mới.
                  </div>
                )}
                {
                  (+props?.statusTracking === 2 || +props?.statusTracking === 5) &&
                  <div style={{ marginTop: 15, textAlign: "justify" }}>
                  Hợp đồng bảo hiểm của bạn đã <b>hoàn thành thanh toán</b>.
                  Hiện tại bạn không còn đơn bảo hiểm sức khoẻ vàng cần gia hạn
                </div>
                }
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => {
                    props.handCancel();
                  }}
                  style={{
                    width: "40%",
                    fontSize: 14,
                    borderRadius: 24,
                    fontWeight: 500,
                    color: "#39B44A",
                    height: 46,
                    border: "1px solid #39B44A",
                  }}
                  color="#ED5A33"
                >
                  Đóng
                </Button>
                <Button
                  onClick={() => {
                    props.handCancel();
                    goStep(2);
                  }}
                  style={{
                    width: "40%",
                    fontSize: 15,
                    background: "#39B44A",
                    borderRadius: 24,
                    fontWeight: 500,
                    color: "#ffffff",
                    height: 46,
                  }}
                  color="#ED5A33"
                >
                  Mua mới
                </Button>
              </div>
            </div>
          </div>
      </Modal>
    </div>
  );
};

export default ModalWarningAboutRepeatOrders;
