import React, { useEffect, useState, useCallback } from "react";
import { DatePicker, Button, Space, Toast } from "antd-mobile";
import moment from "moment";
import Close from "./../../../../assets/suc-khoe-vang/dateclose.png";
import Chose from "./../../../../assets/suc-khoe-vang/datechose.png";
import "./style.css";

const RenderDatePicker = ({
  visible = false,
  min = undefined,
  max = undefined,
  outputFormat = null,
  close = () => null,
  onConfirm = () => null,
  value,
  title
}) => {
  const now = new Date(value?.replace(/(\d+[/])(\d+[/])/, "$2$1") || "");
  // const now = (max || '');

  const labelRenderer = useCallback((type, data) => {
    switch (type) {
      case "month":
        return "tháng " + data;
      default:
        return data;
    }
  }, []);

  return (
    <Space align="center">
      <DatePicker
        className="datePicker-overide"
        min={min}
        max={max}
        visible={visible}
        cancelText={
          <img
            src={Close}
            alt="Global Care"
            width={20}
          />
        }
        title={<b>{title || 'Chọn ngày'}</b>}
        confirmText={
          <img
            src={Chose}
            alt="Global Care"
            width={20}
          />
        }
        renderLabel={labelRenderer}
        onClose={() => close()}
        onConfirm={(value) => {
          onConfirm(moment(value).format(outputFormat || "DD/MM/YYYY"));
        }}
        defaultValue={value ? now : max}
      ></DatePicker>
    </Space>
  );
};

export default RenderDatePicker;
