import React, { useEffect, useState, useContext } from "react";
import { Button, Modal } from "antd";
import idone from "./../../asset/idone.svg";

const MDDone = (props) => {
  useEffect(() => {}, []);

  return (
    <>
      <Modal
        onCancel={props.handleCancel}
        bodyStyle={{
          padding: "13px 15px 13px 15px",
          textAlign: "center",
          fontSize: 14,
        }}
        closable={false}
        open={props.isModalOpen}
        footer={false}
        centered={true}
        style={{ maxWidth: "80%" }}
      >
        <img src={idone} />
        <p style={{ marginTop: 10 }}>
          Gửi yêu cầu thành công. Kết quả sẽ được phản hồi tới email của bạn
          trong 3-5 ngày làm việc.
        </p>
        <Button
          style={{
            width: 102,
            borderRadius: 5,
            height: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#ffffff",
            background: "#0B3C8A",
            margin: "0px auto",
            fontWeight: 700,
            marginTop: 10
          }}
          type="primary"
          onClick={() => props.handleCancel()}
        >
          Đóng
        </Button>
      </Modal>
    </>
  );
};

export default MDDone;
