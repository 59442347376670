import React, { useEffect, useState, useContext, useCallback } from "react";
import { AuthContext } from "./../index";
import { Button, Form, Input, message } from "antd";
import NextIcon from "./../../../assets/suc-khoe-vang/nexticon.svg";
import Service from "./../../../extra/getAPI";
import Helper from "./../../../extra/helper";
import Warning from "./../modal/modalWarningAboutRepeatOrders";

const SearchOrders = () => {
  const { goStep, setDataIsu, setListIsu, MessageErr } =
    useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [listOrder, setListOrder] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  // Dùng để check trạng thái đơn hàng
  const [statusTracking, setStatusTracking] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    const dataSearchSKV = JSON.parse(localStorage.getItem("dataSearchSKV"));
    if (dataSearchSKV) {
      form.setFieldsValue({
        buyerPrivateID: dataSearchSKV?.buyerPrivateID,
        buyerPhone: dataSearchSKV?.buyerPhone,
      });
      onFinish();
    }
  }, []);

  const onFinish = useCallback(async () => {
    form
      .validateFields()
      .then((values) => {
        getDetail(values);
        localStorage.setItem("dataSearchSKV", JSON.stringify(values));
      })
      .catch((errorfields) => {});
  }, [form]);

  const getDetail = (values) => {
    setLoading(true);
    let data = {
      buyerPrivateID: values.buyerPrivateID,
      buyerPhone: values.buyerPhone,
    };
    data = JSON.stringify(data);
    Service.apicall("POST", `/api/v1/product/health-golden/order/detail`, data)
      .then((res) => {
        setLoading(false);
        let data = res?.data;
        setListOrder(data?.result);
        if (data?.result?.length === 0) {
          MessageErr("Không tìm thấy đơn hàng cần gia hạn!");
        }
      })
      .catch((err) => {
        // MessageErr("Lấy phí thất bại.");
        setLoading(false);
      });
  };

  const _renderListOfInsuredPeople = (listInsure) => {
    var data = listInsure;
    var newdata = [];
    for (var i = 0; i < parseInt(data?.length); i++) {
      var item = {
        insuredName: data[i]?.insuredName,
        relationshipWithBuyerName: data[i]?.relationshipWithBuyerName,
        insuredDob: data[i]?.insuredDob,
        insuredGender: data[i]?.insuredGender,
        insuredPrivateID: data[i]?.insuredPrivateID,
        insuredPhone: data[i]?.insuredPhone,
        insuredEmail: data[i]?.insuredEmail,
        insuredAddress: data[i]?.insuredAddress,
        relationshipWithBuyerId: +data[i]?.relationshipWithBuyerId,
        check: true,
        orderMasterCode: data[i]?.orderMasterCode
      };
      newdata.push(item);
    }
    return newdata;
  };

  const NextStep = (values) => {
    const statusTracking = +values?.statusTracking?.value;
    if (
      statusTracking === 2 ||
      statusTracking === 4 ||
      statusTracking === 5 ||
      statusTracking === 6
    ) {
      setOpenWarning(true);
      return;
    }
    setDataIsu({
      primaryAmount: values?.insuranceFeeSingle?.detail?.primary?.amount,
      beginDate: values?.beginDate,
      endDate: values?.endDate,
      period: +values.period,
      buyerName: values?.buyerData?.buyerName,
      buyerDob: Helper._formatDayNoHour(values?.buyerData?.buyerDob),
      buyerPrivateId: values?.buyerData?.buyerPrivateId,
      buyerGender: +values?.buyerData?.buyerGender,
      buyerPhone: values?.buyerData?.buyerPhone,
      buyerEmail: values?.buyerData?.buyerEmail,
      address: values?.buyerData?.buyerAddress,
      qlbosung1: +values?.insuranceFeeSingle?.detail?.additional_1?.amount,
      qlbosung2: +values?.insuranceFeeSingle?.detail?.additional_2?.amount,
      qlbosung3: +values?.insuranceFeeSingle?.detail?.additional_3?.amount,
      fees: values?.insuranceFeeSingle?.fees,
      feesYear: values?.insuranceFeeSingle?.feesYear,
      uniqueCode: values?.uniqueCode,
      signature: values?.signature,
      vat: values?.listOrder[0]?.vatInfo,
      // Số kỳ cần phải đóng hết nếu trễ hạn dưới 30 ngày
      unpaidPeriodTracking: values?.unpaidPeriodTracking,
      statusTracking: +values?.statusTracking?.value,
    });
    setListIsu(_renderListOfInsuredPeople(values?.listOrder));
    goStep(6);
  };

  const handCancel = () => {
    setOpenWarning(false);
  };

  const css = {
    input: { borderRadius: 8, padding: 10, fontSize: 14, color: "#343A40" },
    FormItemsHolder: {
      width: "100%",
      borderRadius: 8,
      margin: "5px 0px",
      padding: 0,
    },
  };

  return (
    <>
      <Warning
        open={openWarning}
        handCancel={handCancel}
        statusTracking={statusTracking}
      />
      <Form
        style={{ fontSize: 14, color: "#343A40" }}
        ref={formRef}
        form={form}
        onFinish={onFinish}
        name="step3skv"
        scrollToFirstError
      >
        <div style={{ overflow: "hidden" }}>
          <div style={{ padding: "10px" }}>
            <div style={{ fontSize: 16, fontWeight: 600, color: "#009B4B" }}>
              Tìm kiếm đơn bảo hiểm mua gia hạn
            </div>
            <div style={{ marginTop: 10 }}>
              <div>
                Số CMND/CCCD/Số định danh cá nhân
                <span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="buyerPrivateID"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thông tin",
                  },
                  {
                    message:
                      "Vui lòng nhập CMND/CCCD 9 số hoặc 12 số, không bao gồm ký tự đặc biệt.",
                    pattern: /^[A-Z][0-9]{7}$|^[0-9]{9}$|^[0-9]{12}$/,
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Input
                  autoComplete="off"
                  style={{ ...css.input }}
                  placeholder="Nhập CMND/CCCD/Hộ chiếu "
                />
              </Form.Item>
            </div>
            <div style={{ marginTop: 10 }}>
              <div>
                Số điện thoại
                <span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="buyerPhone"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thông tin",
                  },
                  {
                    message:
                      "Vui lòng nhập SĐT định dạng số, không bao gồm ký tự đặc biệt.",
                    pattern: /((09|03|07|08|05)+([0-9]{8})\b)/,
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Input
                  autoComplete="off"
                  style={{ ...css.input }}
                  placeholder="Nhập số điện thoại"
                />
              </Form.Item>
            </div>
            <div>
              {listOrder.length > 0 && (
                <div style={{ marginTop: 10 }}>
                  Kết quả tìm kiếm: ({listOrder.length})
                </div>
              )}
              {listOrder.map((data, i) => (
                <div
                  key={i}
                  style={{
                    marginTop: 10,
                    padding: 10,
                    border: "1px solid green",
                    borderRadius: 16,
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between"}}
                  >
                    <span style={{ fontWeight: 500 }}>Người mua BH:</span>
                    <span
                      style={{
                        color: "green",
                        fontWeight: 500,
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        if (+data?.flagTracking === 2) {
                          MessageErr("Thông tin đơn hàng lỗi!");
                          return;
                        }
                        NextStep(data);
                        setStatusTracking(data?.statusTracking?.value);
                      }}
                    >
                      {data?.buyerData?.buyerName}
                      <img src={NextIcon} style={{ marginLeft: 5 }} />
                    </span>
                  </div>
                  <div
                    style={{
                      padding: 10,
                      borderRadius: 8,
                      background: "#F8F9FA",
                      marginTop: 5,
                    }}
                  >
                    {data.listOrder.map((data, i) => (
                      <>
                        <hr
                          style={{
                            margin: "14px 0px 7px 0px",
                            display: i === 0 ? "none" : "",
                          }}
                        />
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span style={{ color: "#646D69" }}>
                              Người được BH:
                            </span>
                            <span style={{ textTransform: "capitalize" }}>
                              {data.insuredName}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: 5,
                              marginBottom: -5,
                            }}
                          >
                            <span style={{ color: "#646D69" }}>
                              Mối quan hệ:
                            </span>
                            <span>{data.relationshipWithBuyerName}</span>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div style={{ height: 86 }}></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "fixed",
              width: "100%",
              maxWidth: 800,
              bottom: 0,
              height: 76,
              background: "#fff",
              borderRadius: "16px 16px 0px 0px",
              alignItems: "center",
              boxShadow: "0px -4px 8px 0px #0000001A",
              padding: "0px 10px",
            }}
          >
            <Button
              onClick={() => {
                goStep(1);
                setDataIsu({});
                setListIsu([]);
                localStorage.removeItem("dataSearchSKV");
              }}
              style={{
                color: "#009B4B",
                background: "#FFF",
                fontWeight: "bold",
                width: "48%",
                height: "44px",
                borderRadius: 8,
                border: "1px solid #009B4B",
              }}
              type="#009B4B"
            >
              Quay lại
            </Button>
            <Button
              htmlType="submit"
              loading={loading}
              style={{
                color: "#FFF",
                background: "#009B4B",
                fontWeight: "bold",
                width: "48%",
                height: "44px",
                borderRadius: 8,
              }}
              type="#009B4B"
            >
              Tra cứu
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default SearchOrders;
