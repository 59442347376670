import React, { useEffect, useState, useContext, useCallback } from "react";
import { AuthContext } from "./../index";
import { Button, Modal, Form, Input, Select } from "antd";
import Close from "./../../../assets/suc-khoe-vang/dateclose.png";
import Idate from "./../../../assets/suc-khoe-vang/idate.png";
import DatePicker from "./DatePicker";
import ModalQuestion from "./modalQuestion";
import moment from "moment";

const ModalAddAndEditPeople = (props) => {
  const { setInsuredPerson, indexEdit, listIsu } = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [formData, setFormData] = useState({});
  const [datePicker, setDatePicker] = useState({});
  const [openQuestion, setOpenQuestion] = useState(false);
  const { Option } = Select;

  useEffect(async () => {
    window.scrollTo(0, 0);
    if (indexEdit !== null) {
      form.setFieldsValue(listIsu[indexEdit]);
      return;
    }
  }, []);

  const onFinish = useCallback(async () => {
    form
      .validateFields()
      .then((values) => {
        // Validation is successful
        setInsuredPerson(values);
        setOpenQuestion(true);
      })
      .catch((errorfields) => {
        console.log(errorfields);
      });
  }, [form]);

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  const handCancel = () => {
    setOpenQuestion(false);
    props.handleCancel();
  };

  return (
    <div style={{ fontSize: 14 }}>
      {openQuestion && (
        <ModalQuestion open={openQuestion} handleCancel={handCancel} />
      )}
      {datePicker.open && (
        <DatePicker
          onConfirm={(data) => {
            form.setFieldsValue({
              insuredDob: data,
            });
            onChangForm({ data: data, name: "insuredDob" });
          }}
          max={moment().subtract(1, "y").toDate()}
          min={moment().subtract(65, "y").add(0, "days").toDate()}
          visible={datePicker.open}
          close={() => {
            setDatePicker({ ...datePicker, open: false });
          }}
          value={formData.insuredDob}
          title={"Ngày sinh"}
        />
      )}
      <Modal
        className="custom-modal-antd"
        style={css.modal}
        centered={true}
        footer={false}
        closable={false}
        open={props.open}
        onCancel={props.handleCancel}
        maskStyle={{ background: "#000000", opacity: 0.8 }}
      >
        <div style={css.divHeder}>
          <div style={{ width: "90%", textAlign: "center" }}>
            {indexEdit !== null
              ? listIsu[indexEdit]?.insuredName
              : "Thông tin thành viên"}
          </div>
          <div>
            <img
              onClick={() => props.handleCancel()}
              src={Close}
              alt="Global Care"
              width={20}
            />
          </div>
        </div>
        <Form
          style={{ fontSize: 14, color: "#343A40" }}
          ref={formRef}
          form={form}
          onFinish={onFinish}
          name="AddAndEdit"
          scrollToFirstError
        >
          <div style={{ padding: 10 }}>
            <div>
              <div>
                Họ và tên<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="insuredName"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thông tin",
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Input
                  autoComplete="off"
                  style={{ ...css.input, textTransform: "capitalize" }}
                  placeholder="Nhập họ và tên"
                />
              </Form.Item>
            </div>
            <div style={{ marginTop: 10 }}>
              <div>
                Ngày sinh<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="insuredDob"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thông tin",
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Input
                  autoComplete="off"
                  suffix={
                    <img style={{ width: 18 }} src={Idate} alt="Global Care" />
                  }
                  readOnly
                  style={{ ...css.input }}
                  placeholder="DD/MM/YYYY"
                  onClick={() => {
                    setDatePicker({ ...datePicker, open: true });
                  }}
                />
              </Form.Item>
            </div>
            <div style={{ marginTop: 10 }}>
              <div>
                Số CMND/CCCD/Số định danh cá nhân
                <span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="insuredPrivateId"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thông tin",
                  },
                  {
                    message:
                      "Vui lòng nhập CMND/CCCD 9 số hoặc 12 số, không bao gồm ký tự đặc biệt.",
                    pattern: /^[A-Z][0-9]{7}$|^[0-9]{9}$|^[0-9]{12}$/,
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Input
                  autoComplete="off"
                  style={{ ...css.input }}
                  placeholder="Nhập CMND/CCCD/Hộ chiếu "
                />
              </Form.Item>
            </div>
            <div style={{ marginTop: 10 }}>
              <div>
                Giới tính
                <span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="insuredGender"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn và nhập thông tin",
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Select
                  allowClear={true}
                  className="custom-select-antd-no-padding-bhyt"
                  placeholder="Chọn giới tính"
                >
                  <Option value={1}>Nam</Option>
                  <Option value={2}>Nữ</Option>
                </Select>
              </Form.Item>
            </div>
            <div style={{ marginTop: 10 }}>
              <div>
                Số điện thoại
                <span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="insuredPhone"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thông tin",
                  },
                  {
                    message:
                      "Vui lòng nhập SĐT định dạng số, không bao gồm ký tự đặc biệt.",
                    pattern: /((09|03|07|08|05)+([0-9]{8})\b)/,
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Input
                  autoComplete="off"
                  style={{ ...css.input }}
                  placeholder="Nhập số điện thoại"
                />
              </Form.Item>
            </div>
            <div style={{ marginTop: 10 }}>
              <div>
                Email
              </div>
              <Form.Item
                name="insuredEmail"
                rules={[
                  {
                    required: false,
                    message: "Vui lòng nhập thông tin",
                  },
                  {
                    message: "Email không hợp lệ",
                    pattern: /\S+@\S+\.\S+/,
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Input
                  autoComplete="off"
                  style={{ ...css.input }}
                  placeholder="Nhập email"
                />
              </Form.Item>
              <div style={{ fontSize: 12, color: "#FB7800" }}>
                Nhập email để nhận giấy chứng nhận bảo hiểm
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <div>
                Địa chỉ thường trú
              </div>
              <Form.Item
                name="permanentAddress"
                rules={[
                  {
                    required:false,
                    message: "Vui lòng nhập thông tin",
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Input
                  autoComplete="off"
                  style={{ ...css.input }}
                  placeholder="Nhập địa chỉ thường trú"
                />
              </Form.Item>
            </div>
            <div style={{ margin: "10px 0px" }}>
              <div>
                Mối quan hệ với Bên mua bảo hiểm
                <span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="relationshipWithBuyerName"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn và nhập thông tin",
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Select
                  allowClear={true}
                  className="custom-select-antd-no-padding-bhyt"
                  placeholder="Chọn mối quan hệ"
                >
                  <Option value={2}>Vợ/chồng</Option>
                  <Option value={3}>Con hợp pháp</Option>
                  <Option value={4}>Ba ruột/ Mẹ ruột</Option>
                  <Option value={5}>Anh ruột/ Chị ruột/ Em ruột</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <div style={css.boxbtn}>
            <Button
              onClick={() => props.handleCancel()}
              style={css.btnBack}
              type="#009B4B"
            >
              Quay lại
            </Button>
            <Button htmlType="submit" style={css.btnNext} type="#009B4B">
              Tiếp tục
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

const css = {
  divHeder: {
    borderBottom: "1px solid #dddddd",
    fontWeight: 500,
    fontSize: 16,
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    backgroundColor: "#FFF",
    borderRadius: "15px 15px 0px 0px",
    zIndex: 100,
  },
  modal: {
    maxWidth: "100%",
    margin: 0,
    verticalAlign: "bottom",
    padding: 0,
    maxHeight: "100vh",
  },
  FormItemsHolder: {
    width: "100%",
    borderRadius: 8,
    margin: "5px 0px",
    padding: 0,
    margin: 0,
  },
  boxbtn: {
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    bottom: 0,
    height: 76,
    background: "#fff",
    borderRadius: "16px 16px 0px 0px",
    display: "flex",
    alignItems: "center",
    boxShadow: "0px -4px 8px 0px #0000001A",
    padding: "0px 10px",
  },
  btnBack: {
    color: "#009B4B",
    background: "#FFF",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
    border: "1px solid #009B4B",
  },
  btnNext: {
    color: "#FFF",
    background: "#009B4B",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
  },
  btnDisa: {
    color: "#C4CFC9",
    background: "#EBF0ED",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
  },
  input: { borderRadius: 8, padding: 10, fontSize: 14, color: "#343A40" },
};

export default ModalAddAndEditPeople;
