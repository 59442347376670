import React, { useEffect, useState, useContext, useCallback } from "react";
import { AuthContext } from "./../index";
import { Modal, Form } from "antd";
import Close from "./../../../assets/suc-khoe-vang/dateclose.png";
import Helper from "./../../../extra/helper";

const ModalViewDetail = (props) => {
  const { listIsu, _render_quan_he, dataIsu, fees } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ fontSize: 14 }}>
      <Modal
        className="custom-modal-antd"
        style={css.modal}
        centered={true}
        footer={false}
        closable={false}
        open={props.open}
        onCancel={props.handleCancel}
        maskStyle={{ background: "#000000", opacity: 0.8 }}
      >
        <div style={css.divHeder}>
          <div style={{ width: "90%", textAlign: "center" }}>
            {listIsu[props.index]?.insuredName}
          </div>
          <div>
            <img
              onClick={() => props.handleCancel()}
              src={Close}
              alt="Global Care"
              width={20}
            />
          </div>
        </div>
        <div style={{ padding: "0px 10px" }}>
          <div style={css.item}>
            <div style={{ color: "#646D69" }}>Họ và tên:</div>
            <div style={css.itemRight}>{listIsu[props.index]?.insuredName}</div>
          </div>
          <div style={css.item}>
            <div style={{ color: "#646D69" }}>Ngày sinh:</div>
            <div style={css.itemRight}>{listIsu[props.index]?.insuredDob}</div>
          </div>
          <div style={css.item}>
            <div style={{ color: "#646D69" }}>
              Số CMND/CCCD/Số định <br /> danh cá nhân:
            </div>
            <div style={css.itemRight}>
              {listIsu[props.index]?.insuredPrivateID ||
                listIsu[props.index]?.insuredPrivateId}
            </div>
          </div>
          <div style={css.item}>
            <div style={{ color: "#646D69" }}>Giới tính:</div>
            <div style={css.itemRight}>
              {listIsu[props.index]?.insuredGender === 1 ||
              listIsu[props.index]?.insuredGender === "Nam"
                ? "Nam"
                : "Nữ"}
            </div>
          </div>
          <div style={css.item}>
            <div style={{ color: "#646D69" }}>Số điện thoại:</div>
            <div style={css.itemRight}>
              {listIsu[props.index]?.insuredPhone}
            </div>
          </div>
          <div style={css.item}>
            <div style={{ color: "#646D69" }}>Email:</div>
            <div style={{ ...css.itemRight, textTransform: "lowercase" }}>
              {listIsu[props.index]?.insuredEmail || "N/A"}
            </div>
          </div>
          <div style={css.item}>
            <div style={{ color: "#646D69", width: "300px" }}>
              Địa chỉ thường trú:
            </div>
            <div style={css.itemRight}>
              {listIsu[props.index]?.permanentAddress ||
                listIsu[props.index]?.insuredAddress ||
                "N/A"}
            </div>
          </div>
          <div style={css.item}>
            <div style={{ color: "#646D69", width: "300px" }}>
              Mối quan hệ với bên mua bảo hiểm:
            </div>
            <div style={css.itemRight}>
              {_render_quan_he(listIsu[props.index]?.relationshipWithBuyerId)}
            </div>
          </div>
          {props?.orderExtension && (
            <div style={{ ...css.item, paddingBottom: 10 }}>
              <div style={{ color: "#646D69" }}>Phí bảo hiểm:</div>
              <div style={css.itemRight}>
                {Helper._formatMoney(dataIsu?.statusTracking !== 3?dataIsu?.fees:dataIsu?.fees*dataIsu?.unpaidPeriodTracking)} VNĐ/
                {dataIsu?.period === 1 && dataIsu?.statusTracking !== 3 ? "tháng" : "năm"}
              </div>
            </div>
          )}
          {!props?.orderExtension && (
            <div style={{ ...css.item, paddingBottom: 10 }}>
              <div style={{ color: "#646D69" }}>Phí bảo hiểm:</div>
              <div style={css.itemRight}>
                {Helper._formatMoney(fees?.fees || dataIsu?.fees)} VNĐ/
                {dataIsu?.period === 1 ? "tháng" : "năm"}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

const css = {
  divHeder: {
    borderBottom: "1px solid #dddddd",
    fontWeight: 500,
    fontSize: 16,
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    backgroundColor: "#FFF",
    borderRadius: "15px 15px 0px 0px",
  },
  modal: {
    maxWidth: "100%",
    margin: 0,
    verticalAlign: "bottom",
    padding: 0,
    maxHeight: "100%",
  },
  item: { display: "flex", justifyContent: "space-between", marginTop: 10 },
  itemRight: {
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "right",
    color: "#383D3B",
  },
};

export default ModalViewDetail;
