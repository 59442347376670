import React, { useEffect, useState, createContext } from "react";
import "antd/dist/antd.css";
import Detail from "./detail";
import CancelOrder from "./cancellationOfInsurance";
import RequestAdditionalModifications from "./requestAdditionalModifications";
import PaymentSchedule from "./paymentSchedule";
import Service from "./../../../../extra/getAPI";
import Helper from "./../../../../extra/helper";
import { message, Spin } from "antd";
export const GlobalStateSeeContract = createContext();

const Index = () => {
  const [step, setStep] = useState(1);
  //thong tin tich luỹ
  const [detailAccumulate, setDetailAccumulate] = useState({});
  const [loading, setLoading] = useState(false);
  // Danh sách kỳ bảo hiểm
  const [paymentSchedule, setPaymentSchedule] = useState({});

  useEffect(() => {
    getDetail();
  }, []);

  const PageView = () => {
    switch (step) {
      case 1:
        // Thông tin đơn hàng
        return <Detail />;
      case 2:
        // Yều cầu huỷ
        return <CancelOrder />;
      case 3:
        // yêu cầu sửa đổi bổ sung
        return <RequestAdditionalModifications />;
      case 4:
        // Lịch thanh toán
        return <PaymentSchedule />;
    }
  };

  // Lấy thông tin tích luỹ
  const getDetail = () => {
    setLoading(true);
    const orderCode = Helper.getParamUrlByKey("orderCode");
    if (!orderCode) {
      message.error("Không tìm thấy thông tin đơn hàng!");
      setLoading(false);
      return;
    }
    Service.apicall(
      "GET",
      `/api/v1/product/health-golden/management/${orderCode}/detail`
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          setDetailAccumulate(res.data.result.heathGolden);
          getPaymentSchedule(res.data.result.heathGolden);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error("Lấy thông tin đơn hàng lỗi!");
      });
  };

  // Lấy danh sách kỳ bảo hiểm
  const getPaymentSchedule = (values) => {
    setLoading(true);
    let data = {
      orderMasterCode: values?.orderMasterCode,
      uniqueCode: values?.uniqueCode,
    };
    data = JSON.stringify(data);
    Service.apicall(
      "POST",
      `/api/v1/product/health-golden/management/periods`,
      data
    )
      .then((res) => {
        setLoading(false);

        if (res.data.statusCode === 200) {
          setPaymentSchedule(res.data.result);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error("Lấy kỳ bảo hiểm thất bại!");
      });
  };

  return (
    <GlobalStateSeeContract.Provider
      value={{ setStep, detailAccumulate, paymentSchedule }}
    >
      <Spin
        spinning={loading}
        tip="Đang lấy thông tin. Vui lòng chờ trong giây lát !"
      >
        <div style={{ maxWidth: 800, margin: "0px auto", color: "#343A40" }}>
          {PageView()}
        </div>
      </Spin>
    </GlobalStateSeeContract.Provider>
  );
};

export default Index;
