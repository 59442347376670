import "./App.css";
import { Route, Switch, BrowserRouter, Router } from "react-router-dom";
import HealthGolden from "./components/suc-khoe-vang/index";
import SeeContract from "./components/manage/page/see-contract/index";
import Accumulation from "./components/manage/page/accumulation/index";
import History from "./routes/history";
function App() {
  return (
    <BrowserRouter>
      <Router history={History}>
        <Switch>
          <Route component={HealthGolden} path="/suc-khoe-vang" />
          <Route component={SeeContract} path="/xem-lai-hop-dong" />
          <Route component={Accumulation} path="/tich-luy" />
        </Switch>
      </Router>
    </BrowserRouter>
  );
}

export default App;
