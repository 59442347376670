import React, { useEffect, useState, createContext } from "react";
import "antd/dist/antd.css";
import SeeContract from "./../see-contract";
import YourContract from "./yourContract";
import Service from "./../../../../extra/getAPI";
import Helper from "./../../../../extra/helper";
import { message, Spin } from "antd";
import Warning from "./../modal/modalWarningAboutRepeatOrders";

export const GlobalStateYourCantract = createContext();

const Index = () => {
  const [step, setStep] = useState(1);
  const [cumulation, setCumulation] = useState();
  const [fees, setFees] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listIsu, setListIsu] = useState([]);
  const [openWarning, setOpenWarning] = useState(false);
  const [statusTracking, setStatusTracking] = useState(0);
  const [dataIsu, setDataIsu] = useState({});

  useEffect(() => {
    getDetail();
  }, []);

  // Lấy thông tin đơn hàng
  const getDetail = () => {
    setLoading(true);
    const orderCode = Helper.getParamUrlByKey("orderCode");
    if (!orderCode) {
      setLoading(false);
      message.error("Không tìm thấy thông tin đơn hàng!");
      return;
    }
    Service.apicall(
      "GET",
      `/api/v1/product/health-golden/management/${orderCode}/cumulation`
    )
      .then((res) => {
        setLoading(false);

        if (res.data.statusCode === 200) {
          setStatusTracking(res.data.result[0].statusTracking.value);
          // setCumulation(res.data.result.heathGolden);
          NextStep(res.data.result[0]);
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error("Lấy thông tin đơn hàng thất bại!");
      });
  };

  // render danh sách người được bảo hiểm
  const _renderListOfInsuredPeople = (listInsure) => {
    var data = listInsure;
    var newdata = [];
    for (var i = 0; i < parseInt(data?.length); i++) {
      var item = {
        insuredName: data[i]?.insuredName,
        relationshipWithBuyerName: data[i]?.relationshipWithBuyerName,
        insuredDob: data[i]?.insuredDob,
        insuredGender: data[i]?.insuredGender,
        insuredPrivateID: data[i]?.insuredPrivateID,
        insuredPhone: data[i]?.insuredPhone,
        insuredEmail: data[i]?.insuredEmail,
        insuredAddress: data[i]?.insuredAddress,
        relationshipWithBuyerId: +data[i]?.relationshipWithBuyerId,
        check: true,
        orderMasterCode: data[i]?.orderMasterCode,
      };
      newdata.push(item);
    }
    return newdata;
  };

  // render danh sách người được bảo hiểm và người mua bảo hiểm
  const NextStep = (values) => {
    const statusTracking = +values?.statusTracking?.value;
    if (
      statusTracking === 2 ||
      statusTracking === 4 ||
      statusTracking === 5 ||
      statusTracking === 6
    ) {
      setOpenWarning(true);
      return;
    }
    setDataIsu({
      primaryAmount: values?.insuranceFeeSingle?.detail?.primary?.amount,
      beginDate: values?.beginDate,
      endDate: values?.endDate,
      period: +values.period,
      buyerName: values?.buyerData?.buyerName,
      buyerDob: Helper._formatDayNoHour(values?.buyerData?.buyerDob),
      buyerPrivateId: values?.buyerData?.buyerPrivateId,
      buyerGender: +values?.buyerData?.buyerGender,
      buyerPhone: values?.buyerData?.buyerPhone,
      buyerEmail: values?.buyerData?.buyerEmail,
      address: values?.buyerData?.buyerAddress,
      qlbosung1: +values?.insuranceFeeSingle?.detail?.additional_1?.amount,
      qlbosung2: +values?.insuranceFeeSingle?.detail?.additional_2?.amount,
      qlbosung3: +values?.insuranceFeeSingle?.detail?.additional_3?.amount,
      fees: values?.insuranceFeeSingle?.fees,
      feesYear: values?.insuranceFeeSingle?.feesYear,
      uniqueCode: values?.uniqueCode,
      signature: values?.signature,
      vat: values?.listOrder[0]?.vatInfo,
      // Số kỳ cần phải đóng hết nếu trễ hạn dưới 30 ngày
      unpaidPeriodTracking: values?.unpaidPeriodTracking,
      statusTracking: +values?.statusTracking?.value,
    });
    setListIsu(_renderListOfInsuredPeople(values?.listOrder));
  };

  const handCancel = () => {
    setOpenWarning(false);
  };

  const PageView = () => {
    switch (step) {
      case 1:
        // Chi tiết hợp đồng
        return <YourContract />;
      case 2:
        // Hợp đồng của bạn
        return <SeeContract />;
    }
  };
  return (
    <GlobalStateYourCantract.Provider value={{ setStep, dataIsu, listIsu, setListIsu,fees, setFees}}>
      <Spin
        spinning={loading}
        tip="Đang lấy thông tin. Vui lòng chờ trong giây lát !"
      >
        <div style={{ maxWidth: 800, margin: "0px auto", color: "#343A40" }}>
          <Warning
            open={openWarning}
            handCancel={handCancel}
            statusTracking={statusTracking}
          />
          {PageView()}
        </div>
      </Spin>
    </GlobalStateYourCantract.Provider>
  );
};

export default Index;
