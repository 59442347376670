import React, { useEffect, useState, useContext, useCallback } from "react";
import { AuthContext } from "./../index";
import { Button, Modal, Radio, Form } from "antd";
import Close from "./../../../assets/suc-khoe-vang/dateclose.png";
import Ivat from "./../../../assets/suc-khoe-vang/ivat.png";

const ModalQuestion = (props) => {
  const { ActionList, insuredPerson,indexEdit} = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [formData, setFormData] = useState({});
  const [disabled, setDisabled] = useState(null);
  const [disabledbtn, setDisabledbtn] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onFinish = useCallback(async () => {
    form
      .validateFields()
      .then((values) => {
        if(indexEdit !== null){
           ActionList("edit", indexEdit, insuredPerson);
           props.handleCancel();
           return;
        }
        if(insuredPerson?.relationshipWithBuyerName === 1){
          ActionList("unshift", "", insuredPerson);
          props.handleCancel();
        }else{
          ActionList("add", "", insuredPerson);
          props.handleCancel();
        }
      })
      .catch((errorfields) => {
        console.log(errorfields);
      });
  }, [form]);

  useEffect(() => {
    if (
      formData?.question1 === 2 ||
      formData?.question2 === 2 ||
      formData?.question3 === 2
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    if (
      formData?.question1 === 1 &&
      formData?.question2 === 1 &&
      formData?.question3 === 1
    ) {
      setDisabledbtn(false);
    } else {
      setDisabledbtn(true);
    }
  }, [formData]);

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  return (
    <div style={{ fontSize: 14 }}>
      <Modal
        className="custom-modal-antd"
        style={css.modal}
        centered={true}
        footer={false}
        closable={false}
        open={props.open}
        onCancel={props.handleCancel}
        maskStyle={{ background: "#000000", opacity: 0.8 }}
      >
        <div style={css.divHeder}>
          <div style={{ width: "90%", textAlign: "center" }}>
            Câu hỏi thẩm định
          </div>
          <div>
            <img
              onClick={() => props.handleCancel()}
              src={Close}
              alt="Global Care"
              width={20}
            />
          </div>
        </div>
        <Form
          style={{ fontSize: 14, color: "#343A40" }}
          ref={formRef}
          form={form}
          onFinish={onFinish}
          name="question"
          scrollToFirstError
        >
          <div style={{ padding: 10 }}>
            <div
              style={{
                padding: 10,
                color: "#FB7800",
                background: "#FFF6EE",
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <img
                style={{ width: 18, marginRight: 10 }}
                src={Ivat}
                alt="Global Care"
              />
              <div>
                Đây là phần câu hỏi bắt buộc, khách hàng cần trả lời tất cả câu
                hỏi phía dưới
              </div>
            </div>
            <div
              style={{
                marginTop: 10,
                padding: 10,
                background: !disabled ? "#F5FCF5" : "#FEF2F2",
                borderRadius: 4,
              }}
            >
              <div style={{ fontWeight: 500, textTransform: "capitalize" }}>
                {insuredPerson?.insuredName}
              </div>
              {!disabled ? (
                <div style={{ fontSize: 12, marginTop: 10 }}>
                  người được bảo hiểm{" "}
                  <span
                    style={{ fontWeight: 500, textTransform: "capitalize" }}
                  >
                    {insuredPerson?.insuredName}
                  </span>{" "}
                  có bị mắc một trong số các bệnh dưới đây không?
                </div>
              ) : (
                <div style={{ fontSize: 12, marginTop: 10 }}>
                  Rất tiếc, khách hàng{" "}
                  <span
                    style={{ fontWeight: 500, textTransform: "capitalize" }}
                  >
                    {insuredPerson?.insuredName}
                  </span>{" "}
                  chưa đủ điều kiệm tham gia gói bảo hiểm này.
                </div>
              )}
            </div>
            <div style={{ marginTop: 10 }}>
              1. Bạn có bị thương tật vĩnh viễn 80% trở lên không?
            </div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              name="question1"
              style={css.FormItemsHolder}
            >
              <Radio.Group
                onChange={(e) => {
                  onChangForm({ data: e.target.value, name: "question1" });
                }}
              >
                <Radio value={1}>Không</Radio>
                <Radio value={2}>Có</Radio>
              </Radio.Group>
            </Form.Item>
            <div style={{ marginTop: 10 }}>
              2. Bạn có bị bệnh ung thư không?
            </div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              name="question2"
              style={css.FormItemsHolder}
            >
              <Radio.Group
                onChange={(e) => {
                  onChangForm({ data: e.target.value, name: "question2" });
                }}
              >
                <Radio value={1}>Không</Radio>
                <Radio value={2}>Có</Radio>
              </Radio.Group>
            </Form.Item>
            <div style={{ marginTop: 10 }}>
              3. Bạn có bị bệnh tâm thần, phong không?
            </div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              name="question3"
              style={css.FormItemsHolder}
            >
              <Radio.Group
                onChange={(e) => {
                  onChangForm({ data: e.target.value, name: "question3" });
                }}
              >
                <Radio value={1}>Không</Radio>
                <Radio value={2}>Có</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div style={css.boxbtn}>
            <Button
              onClick={() => props.handleCancel()}
              style={css.btnBack}
              type="#009B4B"
            >
              Quay lại
            </Button>
            <Button
              disabled={disabledbtn}
              htmlType="submit"
              style={disabledbtn ? css.btnDisa : css.btnNext}
              type="#009B4B"
            >
              Tiếp tục
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

const css = {
  divHeder: {
    borderBottom: "1px solid #dddddd",
    fontWeight: 500,
    fontSize: 16,
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    backgroundColor: "#FFF",
    borderRadius: "15px 15px 0px 0px",
  },
  modal: {
    maxWidth: "100%",
    margin: 0,
    verticalAlign: "bottom",
    padding: 0,
    maxHeight: "100%",
  },
  FormItemsHolder: {
    width: "100%",
    borderRadius: 8,
    margin: "5px 0px",
    padding: 0,
    margin: 0,
  },
  boxbtn: {
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    bottom: 0,
    height: 76,
    background: "#fff",
    borderRadius: "16px 16px 0px 0px",
    display: "flex",
    alignItems: "center",
    boxShadow: "0px -4px 8px 0px #0000001A",
    padding: "0px 10px",
  },
  btnBack: {
    color: "#009B4B",
    background: "#FFF",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
    border: "1px solid #009B4B",
  },
  btnNext: {
    color: "#FFF",
    background: "#009B4B",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
  },
  btnDisa: {
    color: "#C4CFC9",
    background: "#EBF0ED",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
  },
};

export default ModalQuestion;
