import React, { useEffect, useState, useContext, useMemo } from "react";
import { Animated } from "react-animated-css";
import { AuthContext } from "./../index";
import { Button, Form, Input, InputNumber, Checkbox, Select } from "antd";
import moment from "moment";
import Xemql from "./../../../assets/suc-khoe-vang/xemql.png";
import Idate from "./../../../assets/suc-khoe-vang/idate.png";
import DatePicker from "../modal/DatePicker";
import ModalBenifits from "./../modal/modalBenifits";
import Helper from "./../../../extra/helper";

const Benefits = () => {
  const { goStep, dataIsu, setDataIsu, MessageErr, setFees, fees, getFees } =
    useContext(AuthContext);
  const [datePicker, setDatePicker] = useState({});
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [formData, setFormData] = useState({});
  const [openBenifits, setOpenBenifits] = useState(false);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (dataIsu) {
      form.setFieldsValue(dataIsu);
      setFormData(dataIsu);
    }
  }, []);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        setDataIsu({ ...dataIsu, ...values });
        localStorage.setItem(
          "dataIsuskv",
          JSON.stringify({ ...dataIsu, ...values })
        );
        goStep(3);
      })
      .catch((err) => {
        // console.error('err:', err);
      });
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  useEffect(() => {
    getFees(formData);
  }, [
    formData?.period,
    formData?.primaryAmount,
    formData?.qlbosung1,
    formData?.qlbosung2,
    formData?.qlbosung3,
    formData?.checkqlmot?.[0],
    formData?.checkqlhai?.[0],
    formData?.checkqlba?.[0],
  ]);

  const handCancel = () => {
    setOpenBenifits(false);
  };
  return (
    <>
      <ModalBenifits
        formData={formData}
        open={openBenifits}
        handleCancel={handCancel}
      />
      {datePicker.open && (
        <DatePicker
          onConfirm={(data) => {
            console.log("datadate", data);
            form.setFieldsValue({
              beginDate: data,
              endDate: moment(
                new Date(data.replace(/(\d+[/])(\d+[/])/, "$2$1"))
              )
                .add(1, "years")
                .format("DD/MM/YYYY"),
            });
            onChangForm({ data: data, name: "beginDate" });
          }}
          min={moment().add(1, "days").toDate()}
          visible={datePicker.open}
          close={() => {
            setDatePicker({ ...datePicker, open: false });
          }}
          value={formData.beginDate}
          title={"Thời hạn hiệu lực"}
        />
      )}
      <Form
        style={{ fontSize: 14, color: "#343A40" }}
        ref={formRef}
        form={form}
        onFinish={onFinish}
        name="step2skv"
        scrollToFirstError
      >
        <div style={{ padding: 10 }}>
          <div style={css.title}>Quyền lợi bảo hiểm</div>
          <div style={{ fontSize: 12, color: "#FB7800", fontStyle: "italic" }}>
            Chọn quyền lợi bảo hiểm và nhập số tiền bạn muốn tham gia
          </div>
          <div style={{ fontWeight: 600, marginTop: 10 }}>Quyền lợi Chính:</div>
          <div style={{ marginTop: 10 }}>
            Điều trị nội trú do ốm đau, bệnh tật, thai sản
          </div>
          <div style={{ position: "relative" }}>
            <Form.Item
              name="primaryAmount"
              rules={[
                {
                  required: true,
                  message:
                    "Vui lòng nhập số tiền từ 40 triệu đến 400 triệu đồng",
                },
                {
                  pattern: /^((400000000)|([1-3][0-9]{8})|([4-9][0-9]{7}))$/,
                  message:
                    "Vui lòng nhập số tiền từ 40 triệu đến 400 triệu đồng",
                },
              ]}
              style={css.FormItemsHolder}
            >
              <InputNumber
                onChange={(e) => {
                  if (e >= 40000000 && e <= 400000000) {
                    onChangForm({ data: e, name: "primaryAmount" });
                  }
                }}
                // min={40000000}
                // max={400000000}
                // maxLength={11}

                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }
                parser={(value) => value.replace(/(\.*)/g, "")}
                style={css.inputNumber}
                placeholder="Nhập số tiền từ 40 triệu đến 400 triệu"
              />
            </Form.Item>
            <div style={css.vnđ}>VNĐ</div>
          </div>
          <div style={{ fontWeight: 600, marginTop: 10 }}>
            Quyền lợi bổ sung:
          </div>
          <div style={{ marginTop: 10 }}>
            <Form.Item name="checkqlmot" style={css.FormItemsHolder}>
              <Checkbox.Group
                onChange={(e) => {
                  onChangForm({ data: e, name: "checkqlmot" });
                }}
              >
                <Checkbox value="checkqlmot">
                  Tử vong, thương tật vĩnh viễn do ốm đau, bệnh tật, thai sản
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </div>
          {formData?.checkqlmot?.[0] && (
            <div style={{ position: "relative" }}>
              <Form.Item
                name="qlbosung1"
                rules={[
                  {
                    required: true,
                    message:
                      "Vui lòng nhập số tiền từ 40 triệu đến 200 triệu đồng",
                  },
                  {
                    pattern: /^((200000000)|(1[0-9]{8})|([4-9][0-9]{7}))$/,
                    message:
                      "Vui lòng nhập số tiền từ 40 triệu đến 200 triệu đồng",
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <InputNumber
                  onChange={(e) => {
                    if (e >= 40000000 && e <= 200000000) {
                      onChangForm({ data: e, name: "qlbosung1" });
                    }
                  }}
                  // min={40000000}
                  // max={200000000}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  }
                  parser={(value) => value.replace(/(\.*)/g, "")}
                  style={css.inputNumber}
                  placeholder="Nhập số tiền từ 40 triệu đến 200 triệu"
                />
              </Form.Item>
              <div style={css.vnđ}>VNĐ</div>
            </div>
          )}

          <div style={{ marginTop: 10 }}>
            <Form.Item name="checkqlhai" style={css.FormItemsHolder}>
              <Checkbox.Group
                onChange={(e) => {
                  onChangForm({ data: e, name: "checkqlhai" });
                }}
              >
                <Checkbox value="checkqlhai">
                  Tử vong/ thương tật vĩnh viễn do tai nạn
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </div>
          {formData?.checkqlhai?.[0] && (
            <div style={{ position: "relative" }}>
              <Form.Item
                name="qlbosung2"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số tiền từ 40 triệu đến 1 tỷ đồng",
                  },
                  {
                    pattern: /^((1000000000)|([1-9][0-9]{8})|([4-9][0-9]{7}))$/,
                    message: "Vui lòng nhập số tiền từ 40 triệu đến 1 tỷ",
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <InputNumber
                  onChange={(e) => {
                    if (e >= 40000000 && e <= 1000000000) {
                      onChangForm({ data: e, name: "qlbosung2" });
                    }
                  }}
                  // min={40000000}
                  // max={1000000000}
                  // maxLength={13}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  }
                  parser={(value) => value.replace(/(\.*)/g, "")}
                  style={css.inputNumber}
                  placeholder="Nhập số tiền từ 40 triệu đến 1 tỷ"
                />
              </Form.Item>
              <div style={css.vnđ}>VNĐ</div>
            </div>
          )}
          <div style={{ marginTop: 10 }}>
            <Form.Item name="checkqlba" style={css.FormItemsHolder}>
              <Checkbox.Group
                onChange={(e) => {
                  onChangForm({ data: e, name: "checkqlba" });
                }}
              >
                <Checkbox value="checkqlba">Chi phí y tế do tai nạn</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </div>
          {formData?.checkqlba?.[0] && (
            <div style={{ position: "relative" }}>
              <Form.Item
                name="qlbosung3"
                rules={[
                  {
                    required: true,
                    message:
                      "Vui lòng nhập số tiền từ 20 triệu đến 400 triệu đồng",
                  },
                  {
                    pattern: /^((400000000)|([1-3][0-9]{8})|([2-9][0-9]{7}))$/,
                    message:
                      "Vui lòng nhập số tiền từ 20 triệu đến 400 triệu đồng",
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <InputNumber
                  onChange={(e) => {
                    if (+e >= 20000000 && +e <= 400000000) {
                      onChangForm({ data: e, name: "qlbosung3" });
                    }
                  }}
                  // min={20000000}
                  // max={400000000}
                  // maxLength={11}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  }
                  parser={(value) => value.replace(/(\.*)/g, "")}
                  style={css.inputNumber}
                  placeholder="Nhập số tiền từ 20 triệu đến 400 triệu"
                />
              </Form.Item>
              <div style={css.vnđ}>VNĐ</div>
            </div>
          )}
          <div
            onClick={() => {
              if (formData?.primaryAmount) {
                setOpenBenifits(true);
                return;
              }
              MessageErr("Vui lòng chọn quyền lợi chính");
            }}
            style={css.btnxemql}
          >
            Xem chi tiết quyền lợi
            <img
              style={{ width: 13, marginLeft: 10 }}
              src={Xemql}
              alt="Global Care"
            />
          </div>
        </div>
        <div style={css.driver}></div>
        <div style={{ padding: 10 }}>
          <div style={css.title}>Thời hạn bảo hiểm</div>
          <div style={{ marginTop: 10, display: "flex" }}>
            <div style={{ marginRight: 5 }}>
              <div>
                Ngày hiệu lực<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                name="beginDate"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập thông tin",
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Input
                  autoComplete="off"
                  prefix={
                    <img style={{ width: 18 }} src={Idate} alt="Global Care" />
                  }
                  readOnly
                  style={{ ...css.input }}
                  placeholder="DD/MM/YYYY"
                  onClick={() => {
                    setDatePicker({ ...datePicker, open: true });
                  }}
                />
              </Form.Item>
            </div>
            <div>
              <div>
                Ngày kết thúc<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item name="endDate" style={css.FormItemsHolder}>
                <Input
                  autoComplete="off"
                  disabled
                  prefix={
                    <img style={{ width: 18 }} src={Idate} alt="Global Care" />
                  }
                  readOnly
                  style={{ ...css.input }}
                  placeholder="DD/MM/YYYY"
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div style={css.driver}></div>
        <div style={{ padding: 10 }}>
          <div style={{ ...css.title, marginBottom: 10 }}>
            Kỳ hạn góp phí bảo hiểm
          </div>
          <Form.Item
            name="period"
            rules={[
              {
                required: true,
                message: "Vui lòng chọn và nhập thông tin",
              },
            ]}
            style={css.FormItemsHolder}
          >
            <Select
              onChange={(e) => {
                onChangForm({ data: e, name: "period" });
              }}
              allowClear={true}
              className="custom-select-antd-no-padding-bhyt"
              placeholder="Chọn kỳ hạn góp phí bảo hiểm"
            >
              <Option value={1}>Theo tháng</Option>
              {/* <Option value={2}>Theo quý</Option> */}
              <Option value={3}>Theo năm</Option>
            </Select>
          </Form.Item>
          {fees?.fees && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <span>Số tiền góp mỗi kỳ:</span>
              <span style={{ color: "#009B4B" }}>
                <b>{Helper._formatMoney(fees?.fees)} VNĐ/kỳ</b>
              </span>
            </div>
          )}
        </div>
        <div style={css.driver}></div>
        <div style={{ padding: 10 }}>
          <div style={{ ...css.title, marginBottom: 10 }}>Thời gian chờ</div>
          <div style={css.box}>
            <div style={css.item}>
              <div style={css.itemleft}>
                <span style={css.cham}>• </span> Tai nạn
              </div>
              <div style={css.itemright}>Không áp dụng</div>
            </div>
            <div style={css.item}>
              <div style={css.itemleft}>
                <span style={css.cham}>• </span> Điều trị bệnh tật thông thường
              </div>
              <div style={css.itemright}>30 ngày</div>
            </div>
            <div style={css.item}>
              <div style={css.itemleft}>
                <span style={css.cham}>• </span> Điều trị bệnh đặc biệt, bệnh có
                sẵn
              </div>
              <div style={css.itemright}>365 ngày</div>
            </div>
            <div style={css.item}>
              <div style={css.itemleft}>
                <span style={css.cham}>• </span> Sảy thai, nạo phá thai theo chỉ
                định của bác sĩ
              </div>
              <div style={css.itemright}>90 ngày</div>
            </div>
            <div style={css.item}>
              <div style={css.itemleft}>
                <span style={css.cham}>• </span> Sinh đẻ
              </div>
              <div style={css.itemright}>365 ngày</div>
            </div>
            <div style={css.item}>
              <div style={css.itemleft}>
                <span style={css.cham}>• </span> Tử vong, thương tật vĩnh viễn
                do bệnh thông thường
              </div>
              <div style={css.itemright}>30 ngày</div>
            </div>
            <div style={css.item}>
              <div style={css.itemleft}>
                <span style={css.cham}>• </span> Tử vong, thương tật vĩnh viễn
                do bệnh đặc biệt, bệnh có sẵn
              </div>
              <div style={css.itemright}>365 ngày</div>
            </div>
            <div style={{ ...css.item, marginBottom: 0 }}>
              <div style={css.itemleft}>
                <span style={css.cham}>• </span> Tử vong, thương tật vĩnh viễn
                do thai sản
              </div>
              <div style={css.itemright}>365 ngày</div>
            </div>
          </div>
        </div>
        <Animated
          animationIn="fadeInUp"
          animationInDuration={500}
          isVisible={true}
          style={{ width: "100%", position: "sticky", bottom: 0 }}
        >
          <div style={css.boxbtn}>
            <Button
              onClick={() => goStep(1)}
              style={css.btnBack}
              type="#009B4B"
            >
              Quay lại
            </Button>
            <Button
              loading={loading}
              htmlType="submit"
              style={css.btnNext}
              type="#009B4B"
            >
              Tiếp tục
            </Button>
          </div>
        </Animated>
      </Form>
    </>
  );
};

const css = {
  btnNext: {
    color: "#FFF",
    background: "#009B4B",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
  },
  btnBack: {
    color: "#009B4B",
    background: "#FFF",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
    border: "1px solid #009B4B",
  },
  FormItemsHolder: {
    width: "100%",
    borderRadius: 8,
    margin: "5px 0px",
    padding: 0,
  },
  vnđ: {
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    position: "absolute",
    right: 10,
    top: 6,
    cursor: "pointer",
    fontSize: 14,
  },
  inputNumber: {
    width: "100%",
    borderRadius: 8,
    padding: "5px 0px",
    position: "relative",
    fontWeight: "bold",
    fontSize: 14,
    // color: "#0B3C8A",
    border: "1px solid #DCDCDC",
  },
  input: { borderRadius: 8, padding: 10, fontSize: 14, color: "#343A40" },
  btnxemql: {
    background: "#009B4B",
    fontWeight: 600,
    color: "#ffffff",
    height: 40,
    width: 226,
    borderRadius: 42,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px auto",
    boxShadow: "0px 4px 4px 0px #00000040",
    marginTop: 10,
    cursor: "pointer",
  },
  driver: {
    width: "100%",
    height: 5,
    background: "#0000001A",
    marginTop: 10,
  },
  title: {
    fontSize: 16,
    color: "#009B4B",
    fontWeight: 600,
    // marginTop: 10,
  },
  box: {
    padding: 10,
    borderRadius: 8,
    border: "1px solid #009B4B",
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 5,
  },
  itemleft: {
    color: "#646D69",
    display: "flex",
    alignItems: "flex-start",
  },
  itemright: { fontWeight: 600, width: 110, textAlign: "right" },
  boxbtn: {
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    bottom: 0,
    height: 76,
    background: "#fff",
    borderRadius: "16px 16px 0px 0px",
    display: "flex",
    alignItems: "center",
    boxShadow: "0px -4px 8px 0px #0000001A",
    padding: "0px 10px",
  },
  cham: { fontSize: 20, marginRight: 5, marginTop: -5 },
};

export default Benefits;
