import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../index";
import { Animated } from "react-animated-css";
import { Button, Form, Input, Checkbox } from "antd";
import Helper from "./../../../extra/helper";
import Chitiet from "./../../../assets/suc-khoe-vang/chitiet.png";
import Iticket from "./../../../assets/suc-khoe-vang/ticket.png";
import Iedit from "./../../../assets/suc-khoe-vang/iedit.png";
import IViewDetail from "./../../../assets/suc-khoe-vang/viewDetail.png";
import ModalVat from "./../modal/modalvat";
import ModalViewDetail from "./../modal/modalViewDetail";
import Chevronmo from "./../../../assets/suc-khoe-vang/chevronmo.png";
import Chevrondong from "./../../../assets/suc-khoe-vang/chevrondong.png";
import Signater from "./signature";
import ModalBenifitDetails from "./../modal/modalBenifitDetails";
import Service from "./../../../extra/getAPI";
import MdDone from "./../modal/modaldone";

const Create = () => {
  const {
    OpenLink,
    dataIsu,
    listIsu,
    _render_quan_he,
    goStep,
    linkIMG,
    MessageErr,
    fees,
    setFees,
  } = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const platform = Helper.getParamUrlByKey("platform");
  const [formData, setFormData] = useState({});
  const [openVat, setOpenVat] = useState(false);
  const [openViewDetail, setOpenViewDetail] = useState(false);
  const [indexDetail, setIndexDetail] = useState(null);
  const [clear, setClear] = useState(false);
  const [openBenifits, setOpenBenifits] = useState(false);
  const [indexBenifits, setindexBenifits] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataVat] = useState(
    JSON.parse(localStorage.getItem("vatskv")) || null
  );
  const [errRedeem, setErrRedeem] = useState(null);
  const [openDone, setOpenDone] = useState(false);
  const [dataOrder, setDataOrder] = useState({});
  const [close, setClose] = useState({
    quyenloi: true,
    nguoimua: true,
    nguoiduocBH: true,
    vat: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getCommission();
  }, []);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        if (!linkIMG?.signature) {
          window.scrollTo(3000, 3000);
          MessageErr("Vui lòng ký tên để tiếp tục đơn hàng!");
          return;
        }
        createOrder();
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  const _renderListOfInsuredPeople = () => {
    var data = listIsu;
    var newdata = [];
    for (var i = 0; i < parseInt(data?.length); i++) {
      var item = {
        insuredName: data[i]?.insuredName,
        relationshipWithBuyerName: _render_quan_he(
          +data[i]?.relationshipWithBuyerName
        ),
        insuredDob: data[i]?.insuredDob,
        insuredGender: +data[i]?.insuredGender,
        insuredPrivateID: data[i]?.insuredPrivateId,
        insuredPhone: data[i]?.insuredPhone,
        insuredEmail: data[i]?.insuredEmail,
        insuredAddress: data[i]?.permanentAddress,
        relationshipWithBuyerId: +data[i]?.relationshipWithBuyerName,
      };
      newdata.push(item);
    }
    return newdata;
  };

  // Lấy hoa hồng
  const getCommission = () => {
    setLoading(true);
    let data = {
      insuredInfo: {
        primaryAmount: dataIsu?.primaryAmount,
        additionalAmount_1: dataIsu?.checkqlmot?.[0] ? dataIsu?.qlbosung1 : 0,
        additionalAmount_2: dataIsu?.checkqlhai?.[0] ? dataIsu?.qlbosung2 : 0,
        additionalAmount_3: dataIsu?.checkqlba?.[0] ? dataIsu?.qlbosung3 : 0,
        period: dataIsu?.period,
      },
      numberOfInsuredPeople: listIsu.length,
    };
    data = JSON.stringify(data);
    Service.apicall("POST", `/api/v1/product/47/commission`, data)
      .then((res) => {
        setLoading(false);
        let data = res?.data;
        if (res?.data?.statusCode === 200) {
          setFees({ ...fees, commission: data?.result?.commission || 0 });
        }
      })
      .catch((err) => {
        setLoading(false);
        // MessageErr("Lấy phí thất bại.");
      });
  };

  // Tạo đơn
  const createOrder = () => {
    // let reDeem = dataCheckReDeem?.code;
    setLoading(true);
    let platform = Helper.getParamUrlByKey("platform");
    let staff_id = Helper.getParamUrlByKey("staff_id");
    let optional_partner = Helper.getParamUrlByKey("optional_partner");
    let datavat = JSON.parse(localStorage.getItem("vatskv"));
    let partnerStaffId;
    let optionalPartner;
    let redeemCode;
    let vatInfo;
    // if (reDeem) {
    //   redeemCode = reDeem;
    // }
    if (staff_id) {
      partnerStaffId = staff_id;
    }
    if (optional_partner) {
      optionalPartner = optional_partner;
    }
    if (datavat) {
      vatInfo = datavat;
    }
    let data = {
      productCode: "health-golden",
      productId: 47,
      providerId: 12,
      redeemCode,
      // reward: showReward ? 1 : undefined,
      insuredInfo: {
        buyerName: dataIsu?.buyerName,
        buyerDob: dataIsu?.buyerDob,
        buyerGender: dataIsu?.buyerGender,
        buyerPhone: dataIsu?.buyerPhone,
        buyerPrivateID: dataIsu?.buyerPrivateId,
        buyerEmail: dataIsu?.buyerEmail,
        buyerAddress: dataIsu?.address,
        primaryAmount: dataIsu?.primaryAmount,
        additionalAmount_1: dataIsu?.qlbosung1 || 0,
        additionalAmount_2: dataIsu?.qlbosung2 || 0,
        additionalAmount_3: dataIsu?.qlbosung3 || 0,
        beginDate: dataIsu?.beginDate,
        signature: linkIMG?.signature,
        period: dataIsu?.period,
        vatInfo: datavat,
        partnerStaffId,
        optionalPartner,
        vatInfo,
      },
      listOfInsuredPeople: _renderListOfInsuredPeople(),
    };
    data = JSON.stringify(data);
    if (platform === "app") {
      Service.apicall("POST", `/api/v1/order/create`, data)
        .then((res) => {
          setLoading(false);
          let data = res?.data;
          if (data?.statusCode === 200) {
            OpenDone();
            setDataOrder(data.result);
            CustomResetLocalStorage();
          } else {
            MessageErr("Tạo đơn hàng không thành công vui lòng thử lại!");
            // setclickButton(false);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          MessageErr("Tạo đơn hàng không thành công vui lòng thử lại!");
          // setclickButton(false);
        });
    } else {
      Service.apicall(
        "POST",
        `/api/v1/order/create?callback=${encodeURIComponent(window.location.href)}`,
        data
      )
        .then((res) => {
          let data = res?.data;
          if (data.statusCode === 200) {
            OpenDone();
            setDataOrder(data.result);
            CustomResetLocalStorage();
          } else {
            MessageErr("Tạo đơn hàng không thành công vui lòng thử lại!");
            // setclickButton(false);
          }
        })
        .catch((err) => {
          MessageErr("Tạo đơn hàng thất bại vui lòng thử lại!");
          // setclickButton(false);
        });
    }
  };

  const CustomResetLocalStorage = () => {
    localStorage.removeItem("vatskv");
    localStorage.removeItem("listInsuredSKV");
    localStorage.removeItem("dataIsuskv");
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  const openDetailBenifits = (index) => {
    setOpenBenifits(true);
    setindexBenifits(index);
  };

  const handCancel = () => {
    setOpenVat(false);
    setOpenViewDetail(false);
    setIndexDetail(null);
    setOpenBenifits(false);
  };

  const OpenDone = () => {
    setOpenDone(!openDone);
  };

  return (
    <>
      {openDone && <MdDone dataOrder={dataOrder} OpenDone={OpenDone} />}
      <ModalBenifitDetails
        open={openBenifits}
        handleCancel={handCancel}
        indexBenifits={indexBenifits}
      />
      <ModalViewDetail
        index={indexDetail}
        open={openViewDetail}
        handleCancel={handCancel}
        orderExtension={false}
      />
      <ModalVat isModalVisible={openVat} handleCancel={handCancel} />
      <Form
        style={{ fontSize: 14, color: "#343A40", overflow: "hidden" }}
        ref={formRef}
        form={form}
        onFinish={onFinish}
        name="step3skv"
        scrollToFirstError
      >
        <div style={{ padding: 10 }}>
          <div style={css.title}>
            <div>
              Quyền lợi bảo hiểm
              <img
                onClick={() => goStep(2)}
                style={{ width: 15, marginLeft: 5 }}
                src={Iedit}
                alt="Global Care"
              />
            </div>
            <img
              onClick={() => setClose({ ...close, quyenloi: !close.quyenloi })}
              style={{ width: 12, marginLeft: 5 }}
              src={close.quyenloi ? Chevrondong : Chevronmo}
              alt="Global Care"
            />
          </div>
          <div style={{ display: close.quyenloi ? "" : "none" }}>
            <div style={{ display: "flex", marginTop: 10 }}>
              <div style={{ color: "#646D69" }}>
                Điều trị nội trú do ốm đau, bệnh tật, thai sản:
              </div>
              <div style={{ width: "45%", textAlign: "right" }}>
                <div style={{ fontWeight: 500, color: "#383D3B" }}>
                  {Helper._formatMoney(dataIsu?.primaryAmount)} VNĐ
                </div>
                <div
                  onClick={() => openDetailBenifits(1)}
                  style={css.itemRight}
                >
                  Xem chi tiết
                  <img
                    style={{ width: 6, marginLeft: 5 }}
                    src={Chitiet}
                    alt="Global Care"
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <div style={{ color: "#646D69" }}>
                Tử vong, thương tật vĩnh viễn do ốm đau, bệnh tật, thai sản:
              </div>
              <div style={{ width: "45%", textAlign: "right" }}>
                <div style={{ fontWeight: 500, color: "#383D3B" }}>
                  {Helper._formatMoney(dataIsu?.qlbosung1)} VNĐ
                </div>
                <div
                  onClick={() => openDetailBenifits(2)}
                  style={css.itemRight}
                >
                  Xem chi tiết
                  <img
                    style={{ width: 6, marginLeft: 5 }}
                    src={Chitiet}
                    alt="Global Care"
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <div style={{ color: "#646D69" }}>
                Tử vong/ thương tật vĩnh viễn do tai nạn:
              </div>
              <div style={{ width: "45%", textAlign: "right" }}>
                <div style={{ fontWeight: 500, color: "#383D3B" }}>
                  {Helper._formatMoney(dataIsu?.qlbosung2)} VNĐ
                </div>
                <div
                  onClick={() => openDetailBenifits(3)}
                  style={css.itemRight}
                >
                  Xem chi tiết
                  <img
                    style={{ width: 6, marginLeft: 5 }}
                    src={Chitiet}
                    alt="Global Care"
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <div style={{ color: "#646D69" }}>Chi phí y tế do tai nạn:</div>
              <div style={{ width: "45%", textAlign: "right" }}>
                <div style={{ fontWeight: 500, color: "#383D3B" }}>
                  {Helper._formatMoney(dataIsu?.qlbosung3)} VNĐ
                </div>
                <div
                  onClick={() => openDetailBenifits(4)}
                  style={css.itemRight}
                >
                  Xem chi tiết
                  <img
                    style={{ width: 6, marginLeft: 5 }}
                    src={Chitiet}
                    alt="Global Care"
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <div style={{ color: "#646D69" }}>Thời hạn bảo hiểm:</div>
              <div
                style={{ minWidth: "45%", textAlign: "right", fontWeight: 500 }}
              >
                Từ 00:00 - {dataIsu?.beginDate} <br /> Đến 00:00 -{" "}
                {dataIsu?.endDate}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <div style={{ color: "#646D69" }}>Kỳ hạn góp phí bảo hiểm:</div>
              <div
                style={{ width: "45%", textAlign: "right", fontWeight: 500 }}
              >
                {dataIsu?.period === 1 ? "Theo Tháng" : "Theo năm"}
              </div>
            </div>
          </div>
        </div>
        <div style={css.driver}></div>
        <div style={{ padding: 10 }}>
          <div style={css.title}>
            <div>
              Thông tin người mua BH
              <img
                onClick={() => goStep(3)}
                style={{ width: 15, marginLeft: 5 }}
                src={Iedit}
                alt="Global Care"
              />
            </div>
            <img
              onClick={() => setClose({ ...close, nguoimua: !close.nguoimua })}
              style={{ width: 12, marginLeft: 5 }}
              src={close.nguoimua ? Chevrondong : Chevronmo}
              alt="Global Care"
            />
          </div>
          <div style={{ display: close.nguoimua ? "" : "none" }}>
            <div style={css.item}>
              <div style={{ color: "#646D69" }}>Họ và tên:</div>
              <div
                style={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  textAlign: "right",
                }}
              >
                {dataIsu?.buyerName}
              </div>
            </div>
            <div style={css.item}>
              <div style={{ color: "#646D69" }}>Ngày sinh:</div>
              <div
                style={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  textAlign: "right",
                }}
              >
                {dataIsu?.buyerDob}
              </div>
            </div>
            <div style={css.item}>
              <div style={{ color: "#646D69" }}>
                Số CMND/CCCD/Số định <br /> danh cá nhân:
              </div>
              <div
                style={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  textAlign: "right",
                }}
              >
                {dataIsu?.buyerPrivateId}
              </div>
            </div>
            <div style={css.item}>
              <div style={{ color: "#646D69" }}>Giới tính:</div>
              <div
                style={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  textAlign: "right",
                }}
              >
                {dataIsu?.buyerGender === 1 ? "Nam" : "Nữ"}
              </div>
            </div>
            <div style={css.item}>
              <div style={{ color: "#646D69" }}>Số điện thoại:</div>
              <div
                style={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  textAlign: "right",
                }}
              >
                {dataIsu?.buyerPhone}
              </div>
            </div>
            <div style={css.item}>
              <div style={{ color: "#646D69" }}>Email:</div>
              <div
                style={{
                  fontWeight: 500,
                  textAlign: "right",
                }}
              >
                {dataIsu?.buyerEmail}
              </div>
            </div>
            <div style={css.item}>
              <div style={{ color: "#646D69", width: "150px" }}>
                Địa chỉ thường trú:
              </div>
              <div
                style={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  textAlign: "right",
                }}
              >
                {dataIsu?.address}
              </div>
            </div>
          </div>
        </div>
        <div style={css.driver}></div>
        <div style={{ padding: 10 }}>
          <div style={css.title}>
            <div>
              Thông tin người được BH
              <img
                onClick={() => goStep(3)}
                style={{ width: 15, marginLeft: 5 }}
                src={Iedit}
                alt="Global Care"
              />
            </div>
            <img
              onClick={() =>
                setClose({ ...close, nguoiduocBH: !close.nguoiduocBH })
              }
              style={{ width: 12, marginLeft: 5 }}
              src={close.nguoiduocBH ? Chevrondong : Chevronmo}
              alt="Global Care"
            />
          </div>
          <div style={{ display: close.nguoiduocBH ? "" : "none" }}>
            {listIsu.map((data, i) => (
              <div
                key={i}
                style={{
                  marginTop: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 7,
                  borderRadius: 5,
                  background: "#F8F9FA",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>
                    <div
                      style={{
                        color: "#009B4B",
                        fontWeight: 500,
                        textTransform: "capitalize",
                      }}
                    >
                      {data.insuredName}
                    </div>
                    <div style={{ fontSize: 10, color: "#646D69" }}>
                      {_render_quan_he(data?.relationshipWithBuyerName)} -{" "}
                      {data?.buyerDob || data?.insuredDob}
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      marginRight:
                        data?.relationshipWithBuyerName === 1 ? 28 : "",
                    }}
                  >
                    <div style={{ fontSize: 10, color: "#646D69" }}>
                      Phí bảo hiểm
                    </div>
                    <div style={{ fontWeight: 500 }}>
                      {Helper._formatMoney(fees?.fees)} VNĐ/
                      {dataIsu?.period === 1 ? "tháng" : "năm"}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display:
                      data?.relationshipWithBuyerName === 1 ? "none" : "",
                  }}
                >
                  <img
                    onClick={() => {
                      setOpenViewDetail(true);
                      setIndexDetail(i);
                    }}
                    style={{ width: 18, marginLeft: 10 }}
                    src={IViewDetail}
                    alt="Global Care"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {dataVat && (
          <>
            <div style={css.driver}></div>
            <div style={{ padding: 10 }}>
              <div style={css.title}>
                <div>
                  Thông tin xuất hoá đơn
                  <img
                    onClick={() => goStep(3)}
                    style={{ width: 15, marginLeft: 5 }}
                    src={Iedit}
                    alt="Global Care"
                  />
                </div>
                <img
                  onClick={() => setClose({ ...close, vat: !close.vat })}
                  style={{ width: 12, marginLeft: 5 }}
                  src={close.vat ? Chevrondong : Chevronmo}
                  alt="Global Care"
                />
              </div>
              <div style={{ display: close.vat ? "" : "none" }}>
                <div style={css.item}>
                  <div style={{ color: "#646D69" }}>Họ và tên:</div>
                  <div
                    style={{
                      fontWeight: 500,
                      textTransform: "capitalize",
                      textAlign: "right",
                    }}
                  >
                    {dataVat?.name}
                  </div>
                </div>
                <div style={css.item}>
                  <div style={{ color: "#646D69" }}>Mã số thuế:</div>
                  <div
                    style={{
                      fontWeight: 500,
                      textTransform: "capitalize",
                      textAlign: "right",
                    }}
                  >
                    {dataVat?.taxCode}
                  </div>
                </div>
                <div style={css.item}>
                  <div style={{ color: "#646D69", width: "80px" }}>
                    Địa chỉ:
                  </div>
                  <div
                    style={{
                      fontWeight: 500,
                      textTransform: "capitalize",
                      textAlign: "right",
                    }}
                  >
                    {dataVat?.address}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div style={css.driver}></div>
        <div style={{ padding: 10 }}>
          <div>
            <div style={css.titleInput}>Mã ưu đãi</div>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "75%" }}>
                <Form.Item
                  name="redeem"
                  rules={[
                    {
                      required: false,
                      message: "Vui lòng nhập mã ưu đãi của bạn.",
                    },
                  ]}
                  style={{
                    width: "100%",
                    borderRadius: 8,
                    margin: "0px 0px 5px 0px",
                    padding: 0,
                  }}
                >
                  <Input
                    autoComplete="off"
                    name="redeem"
                    style={{ ...css.input }}
                    prefix={<img width={18} src={Iticket} alt="quyen loi" />}
                    placeholder="Nhập mã ưu đãi"
                    // disabled={dataCheckReDeem?.code ? true : false}
                    onChange={(e) => {
                      onChangForm({
                        data: e.target.value,
                        name: e.target.name,
                      });
                      // setUseRedeem(false);
                    }}
                  />
                </Form.Item>
              </div>
              <div style={{ width: "80px" }}>
                <div
                  onClick={() => {
                    if (form.getFieldValue("redeem")) {
                      setErrRedeem("Áp dụng mã ưu đãi không khả dụng");
                    }
                    // if (formData?.redeem) {
                    //   setUseRedeem(true);
                    //   checkReem();
                    // } else {
                    //   setUseRedeem(false);
                    // }
                  }}
                  style={
                    form.getFieldValue("redeem")
                      ? css.buttonRedeem
                      : css.buttonRedeemDisabled
                  }
                >
                  Áp dụng
                </div>
              </div>
            </div>
            <div
              style={{
                color: "red",
                display: errRedeem ? "" : "none",
              }}
            >
              {errRedeem}
            </div>
            {/* <div
                  style={{
                    color: !dataCheckReDeem?.code ? "red" : "green",
                    display: dataCheckReDeem ? "" : "none",
                  }}
                >
                  {!dataCheckReDeem?.code
                    ? "Áp dụng mã giảm giá không khả dụng"
                    : "Áp dụng mã giảm giá thành công"}
                </div> */}
          </div>
          <div
            style={{
              marginTop: 10,
              borderRadius: 4,
              border: "1px solid #009B4B",
              padding: 10,
              lineHeight: 2,
              marginBottom: 15,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Tổng phí bảo hiểm:</span>
              <span>
                {Helper._formatMoney(
                  fees?.fees * (listIsu.length ? listIsu.length : 0)
                )}{" "}
                VNĐ
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Ưu đãi:</span>
              <span>0 VNĐ</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              <span>Tổng tiền thanh toán:</span>
              <span style={{ color: "#009B4B" }}>
                <b>
                  {Helper._formatMoney(
                    fees?.fees * (listIsu.length ? listIsu.length : 0)
                  )}{" "}
                  VNĐ
                </b>
              </span>
            </div>
          </div>
          {platform === "app" && (
            <div
              style={{
                margin: "10px 0px",
                borderRadius: 4,
                border: "1px solid #F58220",
                padding: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                <span>Lương doanh thu:</span>
                <span style={{ color: "#F58220" }}>
                  <b>{Helper._formatMoney(fees?.commission)} VNĐ</b>
                </span>
              </div>
            </div>
          )}
        </div>
        <div style={css.driver}></div>
        <div style={{ padding: 10 }}>
          <div>
            <Form.Item
              name="rules"
              rules={[
                {
                  required: true,
                  message: "Vui lòng đồng ý điều kiện, điều khoản.",
                },
              ]}
              style={css.FormItemsHolder}
            >
              <Checkbox.Group
                // disabled={isOpen}
                style={{ width: "100%" }}
                onChange={(e) => {
                  // console.log(e)
                  // onChangForm({ data: e, name: "rules" });
                }}
              >
                <Checkbox value="rules">
                  <div style={{ color: "#646D69", fontWeight: 400 }}>
                    Tôi cam kết những nội dung trên là đúng sự thật và tôi đã
                    đọc hiểu, đồng ý với{" "}
                    <span
                      onClick={() => {
                        OpenLink(
                          "https://drive.google.com/file/d/1Z4I1dVRtSkYTjcJjZnVV8Y09RufugZDh/preview"
                        );
                      }}
                      style={{
                        color: "#1048B9",
                        textDecoration: "underline",
                      }}
                    >
                      điều kiện, điều khoản
                    </span>{" "}
                    của hợp đồng bảo hiểm.
                    <br />
                    Lưu ý: Khi chọn vào mục này, đồng nghĩa việc kê khai bảo
                    hiểm đã xong và quý khách không thể cập nhật lại.
                  </div>
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "2%",
              }}
            >
              <b style={{ color: "#009B4B" }}>
                Chữ ký của bạn<span style={{ color: "red" }}>*</span>
              </b>
              <b
                onClick={() => setClear(true)}
                style={{ color: "#E16C2B", textDecoration: "underline" }}
              >
                Ký lại
              </b>
            </div>
            <div
              style={{
                border: "1px solid #DDDDDD",
                borderRadius: 10,
                marginBottom: 106,
                minHeight: 159,
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Signater setClear={setClear} clear={clear} />
            </div>
          </div>
        </div>
        <Animated
          animationIn="fadeInUp"
          animationInDuration={500}
          isVisible={true}
          style={{ width: "100%", position: "fixed", bottom: 0 }}
        >
          <div style={css.boxbtn}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 0px",
              }}
            >
              <span>Tổng tiền thanh toán</span>
              <span style={{ color: "#009B4B" }}>
                <b>
                  {Helper._formatMoney(
                    fees?.fees * (listIsu.length ? listIsu.length : 0)
                  )}{" "}
                  VNĐ
                </b>
              </span>
            </div>
            <div style={css.divFlex}>
              <Button
                onClick={() => goStep(3)}
                style={css.btnBack}
                type="#009B4B"
              >
                Quay lại
              </Button>
              <Button
                loading={loading}
                htmlType="submit"
                style={css.btnNext}
                type="#009B4B"
              >
                Tiếp tục
              </Button>
            </div>
          </div>
        </Animated>
      </Form>
    </>
  );
};

const css = {
  divFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 16,
    color: "#009B4B",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  FormItemsHolder: {
    width: "100%",
    borderRadius: 8,
    margin: "5px 0px",
    padding: 0,
  },
  input: { borderRadius: 8, padding: 10, fontSize: 14, color: "#343A40" },
  driver: {
    width: "100%",
    height: 5,
    background: "#0000001A",
  },
  btnBack: {
    color: "#009B4B",
    background: "#FFF",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
    border: "1px solid #009B4B",
  },
  btnNext: {
    color: "#FFF",
    background: "#009B4B",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
  },
  itemRight: {
    color: "#009B4B",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: 12,
  },
  item: { display: "flex", justifyContent: "space-between", marginTop: 10 },
  buttonRedeem: {
    cursor: "pointer",
    fontWeight: 600,
    background: "#F58220",
    borderRadius: 8,
    color: "#ffffff",
    textAlign: "center",
    padding: "9px 0px",
  },
  buttonRedeemDisabled: {
    cursor: "pointer",
    fontWeight: 600,
    background: "#808985",
    borderRadius: 8,
    color: "#FFFFFF",
    textAlign: "center",
    padding: "9px 0px",
    border: "1px solid #d9d9d9",
  },
  boxbtn: {
    position: "fixed",
    bottom: 0,
    height: 96,
    background: "#fff",
    borderRadius: "16px 16px 0px 0px",
    boxShadow: "0px -4px 8px 0px #0000001A",
    padding: "0px 10px",
    width: "100%",
  },
};

export default Create;
