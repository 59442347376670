import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Button, Form, Input, message, Select} from "antd";
import BackStep from "./../../components/backStep";
import MDDone from "./../modal/mdDoneCancel";
import Service from "./../../../../extra/getAPI";
import Helper from "./../../../../extra/helper";
import { GlobalStateSeeContract } from "./index";

const RequestAdditionalModifications = () => {
  const { setStep, detailAccumulate} = useContext(GlobalStateSeeContract);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const { TextArea } = Input;
  const { Option } = Select;

  useEffect(() => {}, []);

  // Huỷ hợp đồng bảo hiểm
  const RequestEdit = (values) => {
    setLoading(true);
    const orderCode = values?.orderCode;
    if (!orderCode) {
      message.error("Không tìm thấy thông tin đơn hàng!");
      setLoading(false);
      return;
    }
    let data = {
      id: 1,
      content: values?.content,
    };
    data = JSON.stringify(data);
    Service.apicall(
      "POST",
      `/api/v1/product/health-golden/management/${orderCode}/request-edit`,
      data
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          setIsModalOpen(true);
          form.resetFields();
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error("Yêu cầu sửa đổi đơn thất bại. Vui lòng thử lại!");
      });
  };

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        RequestEdit(values);
      })
      .catch((err) => {
        // console.error('err:', err);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* <BackStep title="Sửa đổi bổ sung"/> */}
      <MDDone handleCancel={handleCancel} isModalOpen={isModalOpen} />
      <Row style={css.container}>
        <Form
          ref={formRef}
          form={form}
          onFinish={onFinish}
          style={{ width: "100%" }}
        >
          <Col xl={24} style={css.textTitle}>
            Vui lòng chọn người được bảo hiểm muốn sửa đổi thông tin
          </Col>
          <Col xl={24} style={{ width: "100%", marginTop: 15 }}>
            <Form.Item
              name="orderCode"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              style={css.FormItemsHolder}
            >
              <Select
                allowClear={true}
                className="custom-select-antd-no-padding-bhyt"
                placeholder="Chọn người được bảo hiểm"
              >
                {(detailAccumulate.orderList || []).map((data, i) => (
                  <Option key={i} value={data?.orderMasterCode}>
                    {data?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={24} style={{ width: "100%" }}>
            <div style={css.boxCheck}>
              <div>HĐ Bảo hiểm sức khoẻ vàng</div>
            </div>
          </Col>
          <Col style={{ width: "100%" }}>
            <div style={{ marginTop: 15 }}>
              Nội dung muốn sửa đổi
              <span style={{ color: "red" }}>*</span>
            </div>
            <Form.Item
              name="content"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              // style={css.FormItemsHolder}
            >
              <TextArea
                rows={4}
                autoSize={{ minRows: 5, maxRows: 5 }}
                placeholder="Nhập thông tin"
                style={{ borderRadius: 8 }}
              />
            </Form.Item>
          </Col>
          <Col
            xl={24}
            style={{
              position: "fixed",
              bottom: 15,
              width: "100%",
              left: 0,
              right: 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => {
                setStep(1);
              }}
              size="large"
              style={css.buttonBack}
            >
              Quay lại
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                onFinish();
              }}
              size="large"
              style={css.button}
            >
              Gửi yêu cầu
            </Button>
          </Col>
        </Form>
      </Row>
    </>
  );
};

const css = {
  container: {
    padding: "0px 15px",
    width: "100%",
    color: "#1A1A1A",
    background: "#F8F8F8",
    minHeight: "100vh",
    overflow: "unset",
  },
  button: {
    borderRadius: 8,
    background: "#0B3C8A",
    color: "#FFF",
    fontWeight: 700,
    height: 49,
    width: "100%",
    margin: "0px 15px",
  },
  textTitle: { fontWeight: 700, color: "#0B3C8A", marginTop: 15 },
  boxCheck: {
    padding: "10px 12px",
    background: "#FFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 15,
    width: "100%",
  },
  buttonBack: {
    borderRadius: 8,
    background: "#ffffff",
    color: "#0B3C8A",
    fontWeight: 700,
    height: 49,
    width: "100%",
    margin: "0px 15px",
    border: "1px solid #0B3C8A",
  },
};

export default RequestAdditionalModifications;
