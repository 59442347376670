import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../index";
import { Button, Modal, Form, Input, Radio } from "antd";
import Idiachi from "./../../../assets/suc-khoe-vang/idiachi.png";
import Ihoten from "./../../../assets/suc-khoe-vang/ihoten.png";
import Ithoigian from "./../../../assets/suc-khoe-vang/imst.png";
import Close from "./../../../assets/suc-khoe-vang/dateclose.png";

const ModalVAT = (props) => {
  const { vat,setVAT} = useContext(AuthContext);
  // const [vat, setVAT] = useState(null);
  const [typeId, setTypeId] = useState(1);
  const [form] = Form.useForm();
  const formRef = React.createRef();

  useEffect( async () => {
    let dataVAT = JSON.parse(localStorage.getItem("vatskv"));
    if (dataVAT) {
      setVAT(dataVAT);
      form.setFieldsValue(dataVAT);
    } else {
      form.resetFields();
      form.setFieldsValue({typeId: 1});
    }
  }, [props.isModalVisible]);

  const actionFormVAT = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        // console.log(values);
        let vat = {
          ...values,
          typeName: +values?.typeId === 1?'Cá nhân':'Doanh nghiệp'
        }
        localStorage.setItem("vatskv", JSON.stringify(vat));
        setVAT(vat);
        props.handleCancel();
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  return (
    <div style={{ fontSize: 14 }}>
      <Modal
        className="custom-modal-antd"
        style={{
          maxWidth: "100%",
          margin: 0,
          verticalAlign: "bottom",
          padding: 0,
          maxHeight: "90%",
        }}
        centered={true}
        footer={false}
        closable={false}
        open={props.isModalVisible}
        onCancel={props.handleCancel}
        maskStyle={{ background: "#000000", opacity: 0.8 }}
      >
        <Form ref={formRef} form={form} onFinish={actionFormVAT} name="VAT">
          <div>
            <div
              style={{
                borderBottom: "1px solid #dddddd",
                fontWeight: 500,
                fontSize: 16,
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "sticky",
                top: 0,
                backgroundColor: "#FFF",
                borderRadius: "15px 15px 0px 0px",
              }}
            >
              <div style={{ width: "90%", textAlign: "center" }}>
                Thông tin hoá đơn
              </div>
              <div>
                <img
                  onClick={() => props.handleCancel()}
                  src={Close}
                  alt="Global Care"
                  width={20}
                />
              </div>
            </div>
            <div style={{ padding: "10px 10px 0px 10px" }}>
              <div>
                Lựa chọn hoá đơn<span style={{ color: "red" }}>*</span>
              </div>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn và nhập thông tin",
                  },
                ]}
                name="typeId"
                style={css.FormItemsHolder}
              >
                <Radio.Group onChange={(e)=>setTypeId(e.target.value)}>
                  <Radio value={1}>Cá nhân</Radio>
                  <Radio value={2}>Doanh nghiệp</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div
              style={{ padding: "4px 10px 0px 10px", position: "relative" }}
            >
              <div style={css.titleInput}>
                {typeId === 1 ? 'Họ và tên':'Tên doanh nghiệp'}
                <span style={{ color: "red" }}>*</span>
              </div>
              <img style={css.iconInput} src={Ihoten} alt="quyen loi" />
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn và nhập thông tin",
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Input
                  autoComplete="off"
                  style={{...css.input, textTransform:'capitalize'}}
                  placeholder={typeId === 1 ? 'Nhập họ và tên':'Nhập tên công ty'}
                />
              </Form.Item>
            </div>
            <div style={{ padding: "10px 10px 0px 10px" }}>
              <div style={{ marginRight: 5 }}>
                <div style={{ padding: "0px", position: "relative" }}>
                  <div style={css.titleInput}>Mã số thuế<span style={{ color: "red" }}>*</span></div>
                  <img style={css.iconInput} src={Ithoigian} alt="quyen loi" />
                  <Form.Item
                    name="taxCode"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn và nhập thông tin",
                      },
                    ]}
                    style={css.FormItemsHolder}
                  >
                    <Input
                      autoComplete="off"
                      type={"text"}
                      style={css.input}
                      placeholder="Nhập mã số thuế"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "10px 10px 0px 10px",
                position: "relative",
                marginBottom: 20,
              }}
            >
              <div style={css.titleInput}>
                Địa chỉ<span style={{ color: "red" }}>*</span>
              </div>
              <img style={css.iconInput} src={Idiachi} alt="cmnd" />
              <Form.Item
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn và nhập thông tin",
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Input
                  autoComplete="off"
                  style={css.input}
                  placeholder="Nhập địa chỉ"
                />
              </Form.Item>
            </div>
            <div
              style={{
                borderRadius: "16px 16px 0px 0px",
                justifyContent: "center",
                padding: "15px 10px",
                boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.1)",
                width: "100%",
                background: "#ffffff",
              }}
            >
              <Button
                onClick={() => {
                  actionFormVAT();
                }}
                size="large"
                style={{
                  border: "1px solid #009B4B",
                  fontSize: 14,
                  background: "#009B4B",
                  color: "#ffffff",
                  width: "100%",
                  textAlign: "center",
                  borderRadius: 8,
                  fontWeight: 600,
                  height: 45,
                }}
              >
                Cập nhật
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

const css = {
  input: {
    color: "#3A3A3A",
    zIndex: 1,
    fontSize: 15,
    boxShadow: "none",
    padding: "10px 35px",
    borderRadius: 9,
    width: "100%",
  },
  titleInput: { color: "#646D69", marginBottom: 3 },
  FormItemsHolder: {
    width: "100%",
    borderRadius: 8,
    margin: "5px 0px",
    padding: 0,
  },
  iconInput: {
    width: 20,
    margin: "14px 10px",
    position: "absolute",
    zIndex: 2,
  },
};

export default ModalVAT;
