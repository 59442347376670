import React, { useContext } from "react";
import chevon from "./../../asset/chevon-color.svg";
import { Modal } from "antd";

const ModalViewCerlink = (props) => {
  return (
    <div style={{ overflow: "hidden" }}>
      <Modal
        style={{ fontSize: 14 }}
        footer={false}
        closable={false}
        open={props.open}
        onCancel={props.cancel}
        // open={true}
        centered={true}
        className="MDNopadding"
      >
        <div>
          <div
            style={{
              padding: 10,
              fontWeight: 700,
              borderBottom: "1px solid #DCDCDC",
            }}
          >
            Danh sách hợp đồng bảo hiểm
          </div>
          {(props.dataCerlink || []).map((data, i) => (
            <div
              key={i}
              onClick={() =>
                window.ReactNativeWebView.postMessage(
                  `/gotoBrownser?link=${data?.certLink}`
                )
              }
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 10,
                border: "1px solid #DCDCDC",
              }}
            >
              <div style={{width:'100%'}}>
                <div>{data.name}</div>
                <div
                  style={{
                    fontSize: 13,
                    color: "#0B7CE4",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Xem hợp đồng bảo hiểm</div>
                  <img src={chevon} style={{ marginLeft: 5 }} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default ModalViewCerlink;
