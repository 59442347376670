import { Row, Col } from "antd";
import React, { useEffect, useState, useContext, useRef } from "react";
import { AuthContext } from '../index';
import SignatureCanvas from 'react-signature-canvas'


export default function Signater(props) {
  const { uploadG2, setLinkIMG, linkIMG} = useContext(AuthContext);
  const sigPad = useRef();

  useEffect(() => {
    if (props.clear) {
      Clear();
    }
  }, [props.clear]);

  const Clear = () => {
    sigPad.current.clear()
    props.setClear(false);
    setLinkIMG({
      ...linkIMG,
      signature: ''
    });
  }
  const Trim = () => {
    const url = sigPad.current.getTrimmedCanvas().toDataURL("image/png")
    urltoFile(url, 'a.png')
      .then(function (file) {
        uploadG2(file, 'signature', 'signature');
      })
  }
  const urltoFile = (url, filename, mimeType) => {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }
  return (
    <>
      <Row style={{ width: '100%' }}>
        <Col xs={12} style={{ paddingRight: 3 }}>
          <SignatureCanvas penColor='black'
            onEnd={() => Trim()}
            ref={sigPad}
            canvasProps={{ width: '500px', height: 159, className: 'sigCanvas' }}
          />
        </Col>
      </Row>
    </>
  );
}
