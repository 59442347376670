import React, { useEffect, useState, useContext } from "react";
import { Animated } from "react-animated-css";
import BackStep from "./../../components/backStep";
import DotDone from "./../../asset/dot-done.png";
import DotCancel from "./../../asset/dot-cancel.png";
import DotPending from "./../../asset/dot-pending.png";
import DotDefault from "./../../asset/dot-default.png";
import Thugon from "./../../../../assets/suc-khoe-vang/thugon.png";
import Mora from "./../../../../assets/suc-khoe-vang/mora.png";

import Helper from "./../../../../extra/helper";
import DatePicker from "./../modal/DatePicker";
import { GlobalStateSeeContract } from "./index";
import { Row, Col, Timeline,Button } from "antd";

const PaymentSchedule = () => {
  const { paymentSchedule, setStep} = useContext(GlobalStateSeeContract);
  const [datePicker, setDatePicker] = useState({});
  const [formData, setFormData] = useState({});
  const [seeMore, setSeeMore] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const timelineList = paymentSchedule?.periods;

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };
  return (
    <div style={{ background: "#f8f8f8" }}>
      {/* <BackStep backStep={1} title={"Lịch thanh toán"} /> */}
      <Row style={{ padding: 10, minHeight: "100vh" }}>
        <Col xl={24} style={{ width: "100%" }}>
          <div
            style={{
              fontSize: 18,
              color: "#3961A0",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Kỳ bảo hiểm
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: 20,
            }}
          >
            <Input
              autoComplete="off"
              suffix={
                <img style={{ width: 7 }} src={IconSelect} alt="Global Care" />
              }
              readOnly
              value={formData.beginDate}
              style={{ width: 100, borderRadius: 4 }}
              placeholder="MM/YYYY"
              onClick={() => {
                setDatePicker({ ...datePicker, open: true });
              }}
            />
            <div style={{ margin: "0px 10px" }}>Đến</div>
            <Input
              autoComplete="off"
              suffix={
                <img style={{ width: 7 }} src={IconSelect} alt="Global Care" />
              }
              readOnly
              value={formData.beginDate}
              style={{ width: 100, borderRadius: 4 }}
              placeholder="MM/YYYY"
              onClick={() => {
                setDatePicker({ ...datePicker, open: true });
              }}
            />
          </div> */}
          <div
            style={{
              background: "#fff",
              border: "1px solid #dcdcdc",
              borderRadius: 4,
              padding: 10,
              textAlign: "center",
              marginTop: 10,
            }}
          >
            <div style={{ fontSize: 15 }}>
              <b>Tổng phí bảo hiểm còn lại</b>
            </div>
            <div style={{ color: "#0B3C8A", fontSize: 22 }}>
              <b>{Helper._formatMoney(paymentSchedule?.unpaidAmount)}</b>
              <sup style={{ fontSize: 12 }}>đ/năm</sup>
            </div>
          </div>
          <div style={{ marginTop: 20, marginLeft: 6 }}>
            <Timeline
              // pending="Recording..."
              className="timelinelist"
              // reverse={reverse}
              tailWidth={10}
            >
              {timelineList?.map((t, index) => (
                <Animated
                  animationIn="slideInRight"
                  animationOut="slideInLeft"
                  animationInDuration={100 * index}
                  animationOutDuration={100 * index}
                  isVisible={seeMore}
                >
                  <Timeline.Item
                    className={"mau" + t.status}
                    dot={
                      <img
                        src={
                          t.status === 2
                            ? DotDone
                            : t.status === 1
                            ? DotDefault
                            : t.status === 3
                            ? DotPending
                            : DotCancel
                        }
                        style={{ width: 24 }}
                      />
                    }
                    // color={t.color}
                    key={index}
                    style={{
                      fontSize: 20,
                      display: seeMore && index > 3 ? "none" : "",
                    }}
                  >
                    <div style={{ fontSize: 14 }}>
                      Kỳ {index + 1}: {t.periodDate}
                    </div>
                    <div
                      className="timeCustom"
                      style={{ fontSize: 13, color: "#A0A0A0" }}
                    >
                      {t.status === 2
                        ? "Tiền đã thanh toán:"
                        : "Tiền thanh toán:"}{" "}
                      {Helper._formatMoney(t.amount)}đ
                    </div>
                  </Timeline.Item>
                </Animated>
              ))}
            </Timeline>
          </div>
          <div
            onClick={() => setSeeMore(!seeMore)}
            style={{ textAlign: "center", marginBottom: 70}}
          >
            <div style={{ color: "#3961A0" }}>
              {seeMore ? "Xem các kỳ còn lại" : "Thu gọn các kỳ còn lại"}
            </div>
            <div>
              <img src={!seeMore ? Thugon : Mora} style={{ width: 10 }} />
            </div>
          </div>
        </Col>
        <Col
          xl={24}
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            background:'#f8f8f8'
          }}
        >
          <Button
            onClick={() => {
              setStep(1);
            }}
            size="large"
            style={css.button}
          >
            Quay  lại
          </Button>
        </Col>
      </Row>
      {datePicker.open && (
        <DatePicker
          onConfirm={(data) => {
            onChangForm({ data: data, name: "beginDate" });
          }}
          // min={moment().add(1, "days").toDate()}
          visible={datePicker.open}
          close={() => {
            setDatePicker({ ...datePicker, open: false });
          }}
          value={formData.beginDate}
          title={"Chọn thời gian"}
        />
      )}
    </div>
  );
};

const css = {
  button: {
    borderRadius: 8,
    background: "#0B3C8A",
    color: "#FFF",
    fontWeight: 700,
    height: 49,
    width: "100%",
    margin: "15px 15px",
  },
};

export default PaymentSchedule;
