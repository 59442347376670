import React, { useEffect, useState, useContext, useCallback } from "react";
import { GlobalStateYourCantract } from "./index";
import { Button, Form, Input, Checkbox, message } from "antd";
import Helper from "./../../../../extra/helper";
import Chitiet from "./../../../../assets/suc-khoe-vang/chitiet.png";
import Iticket from "./../../../../assets/suc-khoe-vang/ticket.png";
import IViewDetail from "./../../../../assets/suc-khoe-vang/viewDetailBlue.png";
import Chevronmo from "./../../../../assets/suc-khoe-vang/chevronmo.png";
import Chevrondong from "./../../../../assets/suc-khoe-vang/chevrondong.png";
import Check from "./../../../../assets/suc-khoe-vang/checkBlue.png";
import Nocheck from "./../../../../assets/suc-khoe-vang/nocheck.png";
import ModalWarning from "./../modal/modalWarning";
import Service from "./../../../../extra/getAPI";
import MdDone from "./../modal/modaldone";
import iconBack from "./../../asset/iconback.svg";
import History from "../../../../routes/history";

const YourContract = () => {
  const { dataIsu, listIsu, goStep, fees, setFees, setListIsu } = useContext(
    GlobalStateYourCantract
  );
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const platform = Helper.getParamUrlByKey("platform");
  const [formData, setFormData] = useState({});
  const [openVat, setOpenVat] = useState(false);
  const [openViewDetail, setOpenViewDetail] = useState(false);
  const [indexDetail, setIndexDetail] = useState(null);
  const [clear, setClear] = useState(false);
  const [openBenifits, setOpenBenifits] = useState(false);
  const [indexBenifits, setindexBenifits] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errRedeem, setErrRedeem] = useState(null);
  const [openDone, setOpenDone] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [dataOrder, setDataOrder] = useState({});
  const [close, setClose] = useState({
    quyenloi: true,
    nguoimua: true,
    nguoiduocBH: true,
  });

  const [dataEditList, setDataEditList] = useState({
    index: 0,
    values: {},
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        if (listIsu?.filter((item) => item.check)?.length === 0) {
          message.error("Vui lòng chọn người được bảo hiểm");
          return;
        }
        createOrder();
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  // Tạo đơn
  const createOrder = () => {
    // let reDeem = dataCheckReDeem?.code;
    setLoading(true);
    let platform = Helper.getParamUrlByKey("platform");
    let staff_id = Helper.getParamUrlByKey("staff_id");
    let optional_partner = Helper.getParamUrlByKey("optional_partner");
    let datavat = dataIsu?.vat;
    let partnerStaffId;
    let optionalPartner;
    let redeemCode;
    let vatInfo;
    // if (reDeem) {
    //   redeemCode = reDeem;
    // }
    if (staff_id) {
      partnerStaffId = staff_id;
    }
    if (optional_partner) {
      optionalPartner = optional_partner;
    }
    if (datavat) {
      vatInfo = datavat;
    }
    let data = {
      productCode: "health-golden",
      productId: 47,
      providerId: 12,
      redeemCode,
      uniqueCode: dataIsu?.uniqueCode,
      // reward: showReward ? 1 : undefined,
      insuredInfo: {
        // đơn gia hạn do đóng trể 30 ngày thì tuyền 1 key này lên mặc định truyền 2
        restoreInsurance: dataIsu?.statusTracking === 3 ? 1 : 2,
        buyerName: dataIsu?.buyerName,
        buyerDob: dataIsu?.buyerDob,
        buyerGender: dataIsu?.buyerGender,
        buyerPhone: dataIsu?.buyerPhone,
        buyerPrivateID: dataIsu?.buyerPrivateId,
        buyerEmail: dataIsu?.buyerEmail,
        buyerAddress: dataIsu?.address,
        primaryAmount: dataIsu?.primaryAmount,
        additionalAmount_1: dataIsu?.qlbosung1 || 0,
        additionalAmount_2: dataIsu?.qlbosung2 || 0,
        additionalAmount_3: dataIsu?.qlbosung3 || 0,
        beginDate: Helper._formatDayNoHour(dataIsu?.beginDate),
        signature: dataIsu?.signature,
        period: dataIsu?.period,
        vatInfo: datavat,
        partnerStaffId,
        optionalPartner,
        vatInfo,
      },
      listOfInsuredPeople: listIsu,
    };
    data = JSON.stringify(data);
    Service.apicall(
      "POST",
      `/api/v1/order/create?callback=${encodeURIComponent(window.location.href)}`,
      data
    )
      .then((res) => {
        let data = res?.data;
        if (data.statusCode === 200) {
          OpenDone();
          setDataOrder(data.result);
          // CustomResetLocalStorage();
        } else {
          message.error("Tạo đơn hàng không thành công vui lòng thử lại!");
          // setclickButton(false);
        }
      })
      .catch((err) => {
        message.error("Tạo đơn hàng thất bại vui lòng thử lại!");
        // setclickButton(false);
      });
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  const handCancel = () => {
    setOpenVat(false);
    setOpenViewDetail(false);
    setIndexDetail(null);
    setOpenBenifits(false);
    setOpenWarning(false);
  };

  const OpenDone = () => {
    setOpenDone(!openDone);
  };

  const EditList = (index, value) => {
    listIsu[index] = { ...value };
    setListIsu([...listIsu]);
  };

  return (
    <>
      <ModalWarning
        dataEditList={dataEditList}
        EditList={EditList}
        open={openWarning}
        handCancel={handCancel}
      />
      {openDone && <MdDone dataOrder={dataOrder} OpenDone={OpenDone} />}
      <Form
        style={{
          fontSize: 14,
          color: "#343A40",
          overflow: "hidden",
          background: "#f8f8f8",
          minHeight: "98vh",
        }}
        ref={formRef}
        form={form}
        onFinish={onFinish}
        name="step3skv"
        scrollToFirstError
      >
        <div style={css.box}>
          <div style={css.title}>
            <div style={{ color: "#4B4B4B" }}>Quyền lợi bảo hiểm</div>
            <img
              onClick={() => setClose({ ...close, quyenloi: !close.quyenloi })}
              style={{ width: 12, marginLeft: 5 }}
              src={close.quyenloi ? Chevrondong : Chevronmo}
              alt="Global Care"
            />
          </div>
          <div style={{ display: close.quyenloi ? "" : "none" }}>
            <div style={{ display: "flex", marginTop: 10 }}>
              <div style={{ color: "#646D69" }}>
                Điều trị nội trú do ốm đau, bệnh tật, thai sản:
              </div>
              <div style={{ width: "45%", textAlign: "right" }}>
                <div style={{ fontWeight: 500, color: "#383D3B" }}>
                  {Helper._formatMoney(dataIsu?.primaryAmount)} VNĐ
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <div style={{ color: "#646D69" }}>
                Tử vong, thương tật vĩnh viễn do ốm đau, bệnh tật, thai sản:
              </div>
              <div style={{ width: "45%", textAlign: "right" }}>
                <div style={{ fontWeight: 500, color: "#383D3B" }}>
                  {Helper._formatMoney(dataIsu?.qlbosung1)} VNĐ
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <div style={{ color: "#646D69" }}>
                Tử vong/ thương tật vĩnh viễn do tai nạn:
              </div>
              <div style={{ width: "45%", textAlign: "right" }}>
                <div style={{ fontWeight: 500, color: "#383D3B" }}>
                  {Helper._formatMoney(dataIsu?.qlbosung2)} VNĐ
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <div style={{ color: "#646D69" }}>Chi phí y tế do tai nạn:</div>
              <div style={{ width: "45%", textAlign: "right" }}>
                <div style={{ fontWeight: 500, color: "#383D3B" }}>
                  {Helper._formatMoney(dataIsu?.qlbosung3)} VNĐ
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <div style={{ color: "#646D69" }}>Thời hạn bảo hiểm:</div>
              <div
                style={{ minWidth: "45%", textAlign: "right", fontWeight: 500 }}
              >
                Từ 00:00 - {Helper._formatDayNoHour(dataIsu?.beginDate)} <br />{" "}
                Đến 00:00 - {Helper._formatDayNoHour(dataIsu?.endDate)}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <div style={{ color: "#646D69" }}>Kỳ hạn góp phí bảo hiểm:</div>
              <div
                style={{ width: "45%", textAlign: "right", fontWeight: 500 }}
              >
                {dataIsu?.period === 1 && dataIsu?.statusTracking !== 3
                  ? "Theo Tháng"
                  : "Theo năm"}
              </div>
            </div>
          </div>
        </div>
        <div style={css.box}>
          <div style={css.title}>
            <div style={{ color: "#4B4B4B" }}>Thông tin người mua BH</div>
            <img
              onClick={() => setClose({ ...close, nguoimua: !close.nguoimua })}
              style={{ width: 12, marginLeft: 5 }}
              src={close.nguoimua ? Chevrondong : Chevronmo}
              alt="Global Care"
            />
          </div>
          <div style={{ display: close.nguoimua ? "" : "none" }}>
            <div style={css.item}>
              <div style={{ color: "#646D69" }}>Họ và tên:</div>
              <div
                style={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  textAlign: "right",
                }}
              >
                {dataIsu?.buyerName}
              </div>
            </div>
            <div style={css.item}>
              <div style={{ color: "#646D69" }}>Ngày sinh:</div>
              <div
                style={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  textAlign: "right",
                }}
              >
                {dataIsu?.buyerDob}
              </div>
            </div>
            <div style={css.item}>
              <div style={{ color: "#646D69" }}>
                Số CMND/CCCD/Số định <br /> danh cá nhân:
              </div>
              <div
                style={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  textAlign: "right",
                }}
              >
                {dataIsu?.buyerPrivateId}
              </div>
            </div>
            <div style={css.item}>
              <div style={{ color: "#646D69" }}>Giới tính:</div>
              <div
                style={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  textAlign: "right",
                }}
              >
                {dataIsu?.buyerGender === 1 ? "Nam" : "Nữ"}
              </div>
            </div>
            <div style={css.item}>
              <div style={{ color: "#646D69" }}>Số điện thoại:</div>
              <div
                style={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  textAlign: "right",
                }}
              >
                {dataIsu?.buyerPhone}
              </div>
            </div>
            <div style={css.item}>
              <div style={{ color: "#646D69" }}>Email:</div>
              <div
                style={{
                  fontWeight: 500,
                  textAlign: "right",
                }}
              >
                {dataIsu?.buyerEmail}
              </div>
            </div>
            <div style={css.item}>
              <div style={{ color: "#646D69", width: "150px" }}>
                Địa chỉ thường trú:
              </div>
              <div
                style={{
                  fontWeight: 500,
                  textTransform: "capitalize",
                  textAlign: "right",
                }}
              >
                {dataIsu?.address}
              </div>
            </div>
          </div>
        </div>
        <div style={css.box}>
          <div style={css.title}>
            <div style={{ color: "#4B4B4B" }}>Danh sách người được BH</div>
            <img
              onClick={() =>
                setClose({ ...close, nguoiduocBH: !close.nguoiduocBH })
              }
              style={{ width: 12, marginLeft: 5 }}
              src={close.nguoiduocBH ? Chevrondong : Chevronmo}
              alt="Global Care"
            />
          </div>
          <div style={{ display: close.nguoiduocBH ? "" : "none" }}>
            {listIsu?.map((data, i) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {data?.check && (
                  <img
                    onClick={() => {
                      setDataEditList({
                        ...dataEditList,
                        index: i,
                        values: { ...data, check: false },
                      });
                      setOpenWarning(true);
                    }}
                    src={Check}
                    style={{ width: 24, marginRight: 5, height: 24 }}
                  />
                )}
                {!data?.check && (
                  <img
                    onClick={() => {
                      // const { check, ...employeeRest } = data;
                      EditList(i, { ...data, check: true });
                    }}
                    src={Nocheck}
                    style={{ width: 24, marginRight: 5, height: 24 }}
                  />
                )}

                <div
                  key={i}
                  style={{
                    marginTop: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 7,
                    borderRadius: 5,
                    background: "#F8F9FA",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          color: "#0B3C8A",
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}
                      >
                        {data.insuredName}
                      </div>
                      <div style={{ fontSize: 10, color: "#646D69" }}>
                        {data?.relationshipWithBuyerName} - {data.insuredDob}
                      </div>
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        marginRight:
                          data?.relationshipWithBuyerName === 1 ? 28 : "",
                      }}
                    >
                      <div style={{ fontSize: 10, color: "#646D69" }}>
                        Phí bảo hiểm
                      </div>
                      {+dataIsu?.statusTracking !== 3 && (
                        <div style={{ fontWeight: 500 }}>
                          {Helper._formatMoney(dataIsu?.fees)} VNĐ/
                          {dataIsu?.period === 1 ? "tháng" : "năm"}
                        </div>
                      )}
                      {+dataIsu?.statusTracking === 3 && (
                        <div style={{ fontWeight: 500 }}>
                          {Helper._formatMoney(
                            dataIsu?.fees * +dataIsu?.unpaidPeriodTracking
                          )}{" "}
                          VNĐ/năm
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                  // style={{
                  //   display:
                  //     data?.relationshipWithBuyerId === 1 ? "none" : "",
                  // }}
                  >
                    <img
                      onClick={() => {
                        History.push(
                          `/xem-lai-hop-dong?token=${Helper.getParamUrlByKey(
                            "token"
                          )}&orderCode=${Helper.getParamUrlByKey("orderCode")}`
                        );
                      }}
                      style={{ width: 18, marginLeft: 10 }}
                      src={IViewDetail}
                      alt="Global Care"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={css.box}>
          <div>
            <div style={css.titleInput}>Mã ưu đãi</div>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "75%" }}>
                <Form.Item
                  name="redeem"
                  rules={[
                    {
                      required: false,
                      message: "Vui lòng nhập mã ưu đãi của bạn.",
                    },
                  ]}
                  style={{
                    width: "100%",
                    borderRadius: 8,
                    margin: "0px 0px 5px 0px",
                    padding: 0,
                  }}
                >
                  <Input
                    autoComplete="off"
                    name="redeem"
                    style={{ ...css.input }}
                    prefix={<img width={18} src={Iticket} alt="quyen loi" />}
                    placeholder="Nhập mã ưu đãi"
                    // disabled={dataCheckReDeem?.code ? true : false}
                    onChange={(e) => {
                      onChangForm({
                        data: e.target.value,
                        name: e.target.name,
                      });
                      // setUseRedeem(false);
                    }}
                  />
                </Form.Item>
              </div>
              <div style={{ width: "80px" }}>
                <div
                  onClick={() => {
                    if (form.getFieldValue("redeem")) {
                      setErrRedeem("Áp dụng mã ưu đãi không khả dụng");
                    }
                    // if (formData?.redeem) {
                    //   setUseRedeem(true);
                    //   checkReem();
                    // } else {
                    //   setUseRedeem(false);
                    // }
                  }}
                  style={
                    form.getFieldValue("redeem")
                      ? css.buttonRedeem
                      : css.buttonRedeemDisabled
                  }
                >
                  Áp dụng
                </div>
              </div>
            </div>
            <div
              style={{
                color: "red",
                display: errRedeem ? "" : "none",
              }}
            >
              {errRedeem}
            </div>
            {/* <div
                  style={{
                    color: !dataCheckReDeem?.code ? "red" : "green",
                    display: dataCheckReDeem ? "" : "none",
                  }}
                >
                  {!dataCheckReDeem?.code
                    ? "Áp dụng mã giảm giá không khả dụng"
                    : "Áp dụng mã giảm giá thành công"}
                </div> */}
          </div>
          <div
            style={{
              marginTop: 10,
              borderRadius: 4,
              border: "1px solid #0B3C8A",
              padding: 10,
              lineHeight: 2,
              marginBottom: 15,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Tổng phí bảo hiểm:</span>
              {+dataIsu?.statusTracking !== 3 && (
                <span>
                  {Helper._formatMoney(
                    dataIsu?.fees * listIsu?.filter((item) => item.check).length
                  )}{" "}
                  VNĐ
                </span>
              )}
              {+dataIsu?.statusTracking === 3 && (
                <span>
                  {Helper._formatMoney(
                    dataIsu?.fees *
                      +dataIsu?.unpaidPeriodTracking *
                      listIsu?.filter((item) => item.check).length
                  )}{" "}
                  VNĐ
                </span>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Ưu đãi:</span>
              <span>0 VNĐ</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              <span>Tổng tiền thanh toán:</span>
              {+dataIsu?.statusTracking !== 3 && (
                <span style={{ color: "#0B3C8A" }}>
                  <b>
                    {Helper._formatMoney(
                      dataIsu?.fees *
                        listIsu?.filter((item) => item.check).length
                    )}{" "}
                    VNĐ
                  </b>
                </span>
              )}
              {+dataIsu?.statusTracking === 3 && (
                <span style={{ color: "#009B4B" }}>
                  <b>
                    {Helper._formatMoney(
                      dataIsu?.fees *
                        +dataIsu?.unpaidPeriodTracking *
                        listIsu?.filter((item) => item.check).length
                    )}{" "}
                    VNĐ
                  </b>
                </span>
              )}
            </div>
          </div>
        </div>
        <div style={css.boxbtn}>
          {/* <Button onClick={() => goStep(5)} style={css.btnBack} type="#009B4B">
            Quay lại
          </Button> */}
          <Button
            loading={loading}
            htmlType="submit"
            style={css.btnNext}
            type="#009B4B"
          >
            Thanh toán
          </Button>
        </div>
      </Form>
    </>
  );
};

const css = {
  divFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 16,
    color: "#009B4B",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  FormItemsHolder: {
    width: "100%",
    borderRadius: 8,
    margin: "5px 0px",
    padding: 0,
  },
  input: { borderRadius: 8, padding: 10, fontSize: 14, color: "#343A40" },
  driver: {
    width: "100%",
    height: 5,
    background: "#0000001A",
  },
  btnBack: {
    color: "#0B3C8A",
    background: "#FFF",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
    border: "1px solid #0B3C8A",
  },
  btnNext: {
    color: "#FFF",
    background: "#0B3C8A",
    fontWeight: "bold",
    width: "100%",
    height: "44px",
    borderRadius: 8,
  },
  itemRight: {
    color: "#009B4B",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: 12,
  },
  item: { display: "flex", justifyContent: "space-between", marginTop: 10 },
  buttonRedeem: {
    cursor: "pointer",
    fontWeight: 600,
    background: "#F58220",
    borderRadius: 8,
    color: "#ffffff",
    textAlign: "center",
    padding: "9px 0px",
  },
  buttonRedeemDisabled: {
    cursor: "pointer",
    fontWeight: 600,
    background: "#808985",
    borderRadius: 8,
    color: "#FFFFFF",
    textAlign: "center",
    padding: "9px 0px",
    border: "1px solid #d9d9d9",
  },
  boxbtn: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  box: {
    margin: 10,
    padding: 10,
    background: "#ffffff",
    boxShadow: "0px 9px 46px 0px #0000000F",
    borderRadius: 12,
  },
};

export default YourContract;
