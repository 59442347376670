import React, { useEffect, useState, useContext } from "react";
import "antd/dist/antd.css";
import BackStep from "./../../components/backStep";
import yeucauhuy from "./../../asset/yeucauhuy.svg";
import yeucauhuydisa from "./../../asset/yeucauhuydisa.svg";
import yeucau from "./../../asset/yeucau.svg";
import yeucaudisa from "./../../asset/yeucaudisable.svg";
import dieukien from "./../../asset/dieukien.svg";
import dsbenhvien from "./../../asset/dsbenhvien.svg";
import hopdong from "./../../asset/hopdong2.svg";
import zalo from "./../../asset/zalo.png";
import phone from "./../../asset/phone.png";
import email from "./../../asset/email.svg";
import chevon from "./../../asset/chevon-color.svg";
import Helper from "./../../../../extra/helper";
import MdViewCerlink from "./../modal/modalViewCerlink";
import { GlobalStateSeeContract } from "./index";
import { Row, Col, message } from "antd";

const Detail = () => {
  const { setStep, detailAccumulate, paymentSchedule } = useContext(
    GlobalStateSeeContract
  );
  const [openCerlink, setOpenCerlink] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handCancel = () => {
    setOpenCerlink(false);
  };

  const arrItem = [
    {
      id: 1,
      title: "Hợp đồng bảo hiểm",
      icon: hopdong,
      // link: "",
      disable: false,
    },
    {
      id: 2,
      title: "Danh sách bệnh viện",
      icon: dsbenhvien,
      link: "http://www.baominh.com.vn/uploads/source/DS%20BVLP%20CAP%20NHAT%2025-04-2023.pdf",
      disable: false,
    },
    {
      id: 3,
      title: "Điều kiện, điều khoản",
      icon: dieukien,
      link: "https://drive.google.com/file/d/1xfyRjnjjTNloEaGRGF3Qg_xRaXEfE_Sn/view?usp=drive_link",
      disable: false,
    },
    {
      id: 4,
      title: "Yêu cầu sửa đổi bổ sung",
      icon: +detailAccumulate?.status?.value !== 1 ? yeucaudisa : yeucau,
      step: 3,
      disable: +detailAccumulate?.status?.value !== 1 ? true : false,
    },
    {
      id: 5,
      title: "Yêu cầu huỷ bảo hiểm",
      icon: +detailAccumulate?.status?.value !== 1 ? yeucauhuydisa : yeucauhuy,
      step: 2,
      disable: +detailAccumulate?.status?.value !== 1 ? true : false,
    },
  ];

  return (
    <div style={{ background: "#f8f8f8", height: "100%" }}>
      <MdViewCerlink
        open={openCerlink}
        cancel={handCancel}
        dataCerlink={detailAccumulate.orderList}
      />
      {/* <BackStep title={"Bảo hiểm sức khoẻ vàng"} /> */}
      <Row style={{ padding: 10, minHeight: "100vh" }}>
        {(+detailAccumulate?.status?.value === 4 ||
          +detailAccumulate?.status?.value === 6 ||
          +detailAccumulate?.status?.value === 3) && (
          <Col
            xl={24}
            style={{
              width: "100%",
              textAlign: "center",
              color: "red",
              marginBottom: 10,
            }}
          >
            <i>
              {+detailAccumulate?.status?.value === 4 && (
                <div>
                  Hợp đồng bảo hiểm của bạn bị chấm dứt{" "}
                  {detailAccumulate?.cancelDate
                    ? "Từ " + Helper._formatDay(detailAccumulate?.cancelDate)
                    : ""}{" "}
                  do không hoàn thành nghĩa vụ thanh toán phí bảo hiểm.
                </div>
              )}
              {+detailAccumulate?.status?.value === 6 && (
                <div>
                  Hợp đồng bảo hiểm của bạn bị chấm dứt{" "}
                  {detailAccumulate?.cancelDate
                    ? "Từ " + Helper._formatDay(detailAccumulate?.cancelDate)
                    : ""}{" "}
                  do người mua bảo hiểm xác nhận không tiếp tục đóng phí.
                </div>
              )}
              {+detailAccumulate?.status?.value === 3 && (
                <div>
                  Hợp đồng bảo hiểm của bạn bị chấm dứt{" "}
                  {detailAccumulate?.cancelDate
                    ? "Từ " + Helper._formatDay(detailAccumulate?.cancelDate)
                    : ""}{" "}
                  do không hoàn thành nghĩa vụ thanh toán phí bảo hiểm.
                  <br />
                  Để khôi phục bạn vào mục trả góp bảo hiểm và thực hiện thanh
                  toán phí.
                </div>
              )}
              <br />
            </i>
          </Col>
        )}
        <Col xl={24}>
          <div
            style={{
              fontWeight: 600,
              color: "#4B4B4B",
            }}
          >
            Thông tin thanh toán
          </div>
        </Col>
        <Col xl={24} style={{ ...css.boxTitle }}>
          <div style={css.line}>
            <div style={css.widthLine}>Trạng thái đơn bảo hiểm</div>
            <div style={css.textLine}>
              {+detailAccumulate?.status?.value === 2
                ? "Hoàn thành"
                : +detailAccumulate?.status?.value === 6
                ? "Tự Huỷ"
                : detailAccumulate?.status?.content}
            </div>
          </div>
          <div style={css.line}>
            <div style={css.widthLine}>Kỳ góp phí</div>
            <div style={css.textLine}>
              {detailAccumulate.period === 1
                ? "Theo tháng"
                : detailAccumulate.period === 2
                ? "Theo quý"
                : "Theo năm"}
            </div>
          </div>
          <div
            style={{
              ...css.line,
              display:
                detailAccumulate?.totalPeriodSucces === 12 ? "none" : "flex",
            }}
          >
            <div style={css.widthLine}>Kỳ thanh toán kế tiếp</div>
            {detailAccumulate?.totalPeriodSucces && (
              <div style={css.textLine}>
                {Helper._formatDayNoHour(
                  detailAccumulate?.periodDate[
                    +detailAccumulate?.totalPeriodSucces
                  ]?.date
                )}
              </div>
            )}
          </div>
          <div style={css.line}>
            <div style={css.widthLine}>Số tiền cần thanh toán</div>
            <div style={css.textLine}>
              {Helper._formatMoney(
                +detailAccumulate?.insuranceFees?.fees *
                  +detailAccumulate?.totalInsuredPeople
              )}
              đ
            </div>
          </div>
          {+detailAccumulate?.status?.value === 3 && (
            <div style={css.line}>
              <div style={css.widthLine}>Ngày nợ phí</div>
              <div style={css.textLine}>{detailAccumulate?.debtDate} Ngày</div>
            </div>
          )}
        </Col>
        <Col xl={24}>
          <div
            style={{
              fontWeight: 600,
              color: "#4B4B4B",
              marginTop: 20,
            }}
          >
            Quyền lợi bảo hiểm sức khoẻ vàng
          </div>
        </Col>
        <Col xl={24} style={css.boxTitle}>
          <div style={css.line}>
            <div style={css.widthLine}>
              Điều trị nội trú do ốm đau, bệnh tật, thai sản:
            </div>
            <div style={css.textLine}>
              {Helper._formatMoney(
                +detailAccumulate?.insuranceFees?.detail?.primary?.amount
              )}
              đ
            </div>
          </div>
          <div style={css.line}>
            <div style={css.widthLine}>
              Tử vong, thương tật vĩnh viễn do ốm đau, bệnh tật, thai sản:
            </div>
            <div style={css.textLine}>
              {Helper._formatMoney(
                +detailAccumulate?.insuranceFees?.detail?.additional_1?.amount
              )}
              đ
            </div>
          </div>
          <div style={css.line}>
            <div style={css.widthLine}>
              Tử vong/ thương tật vĩnh viễn do tai nạn:
            </div>
            <div style={css.textLine}>
              {Helper._formatMoney(
                +detailAccumulate?.insuranceFees?.detail?.additional_2?.amount
              )}
              đ
            </div>
          </div>
          <div style={css.line}>
            <div style={css.widthLine}>Chi phí y tế do tai nạn:</div>
            <div style={css.textLine}>
              {Helper._formatMoney(
                +detailAccumulate?.insuranceFees?.detail?.additional_3?.amount
              )}
              đ
            </div>
          </div>
        </Col>
        <div
          onClick={() => {
            if (!paymentSchedule?.periods) {
              message.error("Không tìm thấy kỳ thanh toán!");
              return;
            }
            setStep(4);
          }}
          style={{
            width: "100%",
            color: "#0B7CE4",
            textAlign: "right",
            marginTop: 5,
          }}
        >
          Lịch thanh toán
          <img src={chevon} style={{ marginLeft: 5 }} />
        </div>
        <Col xl={24}>
          <Row gutter={15} style={css.boxItem}>
            {(arrItem || []).map((data, i) => (
              <Col
                onClick={() => {
                  if (data?.step && !data.disable) {
                    setStep(data?.step);
                  }
                  if (data.id === 1) {
                    setOpenCerlink(true);
                  }
                  if (data.link) {
                    window.ReactNativeWebView.postMessage(
                      `/gotoBrownser?link=${data.link}`
                    );
                  }
                }}
                key={i}
                xl={8}
                style={
                  data.id % 2
                    ? css.itemLeft
                    : {
                        ...css.itemRight,
                        marginLeft: +data.id === 4 ? "16.5%" : 0,
                      }
                }
              >
                <div
                  style={{
                    ...css.item,
                    border: data.disable
                      ? "1px solid #686868"
                      : "1px solid #6685B6",
                  }}
                >
                  <div style={css.itemTitle}>
                    <img src={data.icon} />
                  </div>
                  {data.title}
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col xl={24} style={{ margin: "0px auto" }}>
          <div style={{ fontWeight: "bold" }}>
            Liên hệ CSKH để được{" "}
            <span style={{ color: "#0B3C8A" }}>hỗ trợ</span> và{" "}
            <span style={{ color: "#0B3C8A" }}>hướng dẫn.</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              margin: "15px auto",
            }}
          >
            <img
              onClick={() => {
                window.ReactNativeWebView.postMessage("/gotozalo");
              }}
              style={{ width: 60 }}
              src={zalo}
              alt="zalo"
            />
            <img
              onClick={() => {
                window.open("tel:1900292989", "_self");
                // window.ReactNativeWebView.postMessage("/gotosupport");
              }}
              style={{ width: 60 }}
              src={phone}
              alt="phone"
            />
            <img
              onClick={() => {
                window.ReactNativeWebView.postMessage("/gotosupport");
              }}
              style={{ width: 45, marginTop: -13 }}
              src={email}
              alt="email"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const css = {
  line: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    borderBottom: "1px solid #DCDCDC",
  },
  item: {
    height: "92px",
    padding: "2px 5px 10px 5px",
    textAlign: "center",
    border: "1px solid #6685B6",
    borderRadius: 13,
    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.1)",
    background: "#FFF",
    cursor: "pointer",
  },
  itemLeft: {
    padding: "2%",
    width: "33.33%",
  },
  itemRight: {
    padding: "2%",
    width: "33.33%",
  },
  itemTitle: { margin: "10px 0px" },
  textLine: { fontWeight: 700, color: "#4B4B4B" },
  textWarning: {
    fontWeight: 400,
    color: "red",
    marginTop: 15,
    textAlign: "center",
    width: "100%",
    paddingBottom: "69vh",
  },
  widthLine: { maxWidth: "60%" },
  boxTitle: {
    width: "100%",
    marginTop: 5,
    background: "#ffffff",
    color: "#686868",
  },
  boxItem: { color: "#4B4B4B", lineHeight: 1, margin: "15px 0px 15px 0px" },
};

export default Detail;
