import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../index";
import { Modal } from "antd";
import Close from "./../../../assets/suc-khoe-vang/dateclose.png";
import Chevronmo from "./../../../assets/suc-khoe-vang/chevronmo.png";
import Chevrondong from "./../../../assets/suc-khoe-vang/chevrondong.png";
import Helper from "./../../../extra/helper";

const ModalBenifits = (props) => {
  const { dataIsu } = useContext(AuthContext);
  const [openql1, setOpenql1] = useState(true);
  const [openqlbosung, setOpenqlbosung] = useState(false);
  const Benefits = props?.formData || dataIsu;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ fontSize: 14 }}>
      <Modal
        className="custom-modal-antd"
        style={{
          maxWidth: "100%",
          margin: 0,
          verticalAlign: "bottom",
          padding: 0,
          maxHeight: "95%",
        }}
        centered={true}
        footer={false}
        closable={false}
        open={props.open}
        onCancel={props.handleCancel}
        maskStyle={{ background: "#000000", opacity: 0.8 }}
      >
        <div
          style={{
            borderBottom: "1px solid #dddddd",
            fontWeight: 500,
            fontSize: 16,
            padding: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "sticky",
            top: 0,
            backgroundColor: "#FFF",
            borderRadius: "15px 15px 0px 0px",
          }}
        >
          <div style={{ width: "90%", textAlign: "center", fontWeight: 600}}>
            Bảng quyền lợi
          </div>
          <div>
            <img
              onClick={() => props.handleCancel()}
              src={Close}
              alt="Global Care"
              width={20}
            />
          </div>
        </div>
        <div style={{ padding: 10 }}>
          <div
            onClick={() => setOpenql1(!openql1)}
            style={{
              color: "#009B4B",
              fontWeight: 600,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "-moz-initial",
            }}
          >
            <div>Quyền lợi chính:</div>
            <div>
              <img
                src={openql1 ? Chevrondong : Chevronmo}
                alt="Global Care"
                width={13}
              />
            </div>
          </div>
          <div style={{ display: openql1 ? "" : "none" }}>
            <table style={{ width: "100%", marginTop: 10 }}>
              <tr>
                <th
                  style={{
                    width: "75%",
                    textAlign: "center",
                    borderRadius: "10px 0px 0px 0px",
                  }}
                >
                  Quyền lợi
                </th>
                <th
                  style={{
                    textAlign: "center",
                    borderRadius: "0px 10px 0px 0px",
                  }}
                >
                  Số tiền BH (VNĐ)
                </th>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>
                  1. Điều trị nội trú do ốm đau, bệnh tật, thai sản
                </td>
                <td style={{ textAlign: "center", fontWeight: "bold" }}>
                  {Helper._formatMoney(Benefits?.primaryAmount)}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  Giới hạn tối đa/năm/người và theo các giới hạn phụ sau:
                </td>
                <td></td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  <b>1.1 Viện phí</b> (tối đa 60 ngày/năm) bồi thường chi phí về
                  dịch vụ y tế, dược phẩm cung cấp bởi bệnh viện phát sinh trong
                  thời gian nằm viện (bao gồm điều trị cấp cứu), bao gồm nhưng
                  không giới hạn:
                  <ul style={{ margin: 0 }}>
                    <li>
                      Chi phí giường, phòng (chi phí ăn theo phòng tương ứng
                      được cung cấp bởi bệnh viện), bao gồm tất cả loại phòng(kể
                      cả phòng VIP, phòng bao): Tối đa 20% viện phí/ngày nhưng
                      không vượt quá 2.000.000 vnđ/ngày.
                    </li>
                    <li>
                      Chi phí phòng chăm sóc đặc biệt (phòng điều trị tích cực)
                    </li>
                    <li>Chi phí hành chánh, chi phí máu, huyết tương;</li>
                    <li>Thuốc và dược phẩm sử dụng trong khi nằm viện;</li>
                    <li>Băng, nẹp thông thường và bột;</li>
                    <li>
                      Vật lý trị liệu (nếu những chi phí này là cần thiết trong
                      quá trình điều trị nằm viện theo chỉ định của bác sĩ);
                    </li>
                    <li>Tiêm truyền tĩnh mạch;</li>
                    <li>
                      Xét nghiệm hoặc chẩn đoán bằng hình ảnh như X-quang, MRI,
                      CT, PET được chỉ định bởi bác sĩ điều trị để chẩn đoán
                      bệnh, được thực hiện trong thời gian điều trị nằm viện tại
                      bệnh viện;
                    </li>
                    <li>Chi phí điều trị trong ngày.</li>
                    <li>Chi phí đỡ đẻ.</li>
                  </ul>
                </td>
                <td style={{ textAlign: "center" }}>
                  {Helper._formatMoney(Benefits?.primaryAmount * (5 / 100))}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  <b>1.2 Chi phí phẫu thuật</b> (chi phí phát sinh liên quan đến
                  một ca phẫu thuật/thủ thuật nội trú, phẫu thuật/thủ thuật
                  trong ngày) bao gồm nhưng không giới hạn cho các chi phí sau:
                  <ul style={{ margin: 0 }}>
                    <li>Thủ thuật phẫu thuật;</li>
                    <li>Phòng mổ;</li>
                    <li>Gây mê;</li>
                    <li>Phẫu thuật viên;</li>
                    <li>
                      Chi phí thông thường cho các chẩn đoán trước khi mổ;
                    </li>
                    <li>Hồi sức sau khi mổ;</li>
                    <li>Tái mổ;</li>
                    <li>
                      Phẫu thuật ghép cơ quan cơ thể, không bao gồm chi phí mua
                      tạng và hiến tạng.
                    </li>
                  </ul>
                </td>
                <td style={{ textAlign: "center" }}>
                  {Helper._formatMoney(Benefits?.primaryAmount)}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  <b>1.3 Chi phí vận chuyển cấp cứu</b> (loại trừ đường hàng
                  không, taxi, xe công nghệ)
                </td>
                <td style={{ textAlign: "center" }}>
                  {Helper._formatMoney(Benefits?.primaryAmount)}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  <b>1.4 Trợ cấp nằm viện</b>. Tối đa 60 ngày/năm
                </td>
                <td style={{ textAlign: "center" }}>
                  {Helper._formatMoney(Benefits?.primaryAmount * (0.1 / 100))}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  <b>1.5 Trợ cấp mai táng</b> với điều kiện người được bảo hiểm
                  có phát sinh chi phí y tế trong vòng 03 tháng trước khi tử
                  vong (bất kể nằm viện hay điều trị ngoại trú)
                </td>
                <td>2.000.000</td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  <b>1.6 Chi phí điều trị trước khi nhập viện</b> (trong vòng 30
                  ngày trước khi nhập viện)
                </td>
                <td style={{ textAlign: "center" }}>
                  {Helper._formatMoney(Benefits?.primaryAmount * (5 / 100))}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  <b>1.7 Chi phí Điều trị sau khi xuất viện</b> (trong vòng 30
                  ngày sau khi xuất viện)
                </td>
                <td style={{ textAlign: "center" }}>
                  {Helper._formatMoney(Benefits?.primaryAmount * (5 / 100))}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  <b>1.8 Chi phí Y tá chăm sóc tại nhà</b> (trong vòng 30 ngày
                  sau khi xuất viện)
                </td>
                <td style={{ textAlign: "center" }}>
                  {Helper._formatMoney(Benefits?.primaryAmount * (5 / 100))}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  <b>1.9 Chăm sóc thai sản</b>
                </td>
                <td style={{ textAlign: "center" }}>
                  {Helper._formatMoney(Benefits?.primaryAmount * (20 / 100))}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  <ul style={{ margin: 0 }}>
                    <li>Sinh thường</li>
                  </ul>
                </td>
                <td rowSpan={2} style={{ textAlign: "center" }}>
                  Theo các giới hạn phụ tại 1.1 và/hoặc 1.2
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  <ul style={{ margin: 0 }}>
                    <li>Sinh mổ, biến chứng thai sản</li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
          <div
            onClick={() => setOpenqlbosung(!openqlbosung)}
            style={{
              color: "#009B4B",
              fontWeight: 600,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "-moz-initial",
              marginTop: 10,
            }}
          >
            <div>Quyền lợi bổ sung:</div>
            <div>
              <img
                src={openqlbosung ? Chevrondong : Chevronmo}
                alt="Global Care"
                width={13}
              />
            </div>
          </div>
          <div style={{ display: openqlbosung ? "" : "none" }}>
            <table style={{ width: "100%", marginTop: 10 }}>
              <tr>
                <th
                  style={{
                    width: "75%",
                    textAlign: "center",
                    borderRadius: "10px 0px 0px 0px",
                  }}
                >
                  Quyền lợi
                </th>
                <th
                  style={{
                    textAlign: "center",
                    borderRadius: "0px 10px 0px 0px",
                  }}
                >
                  Số tiền BH (VNĐ)
                </th>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>
                  2. Tử vong, thương tật vĩnh viễn do đau ốm, bệnh tật, thai sản
                </td>
                <td style={{ textAlign: "center", fontWeight: "bold" }}>
                  {Helper._formatMoney(Benefits?.qlbosung1)}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  2.1 Tử vong/ thương tật toàn bộ vĩnh viễn
                </td>
                <td style={{ textAlign: "center" }}>100% số tiền BH</td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  2.2 Thương tật bộ phận vĩnh viễn
                </td>
                <td style={{ textAlign: "center" }}>
                  Chi trả theo bảng tỷ lệ thương tật đính kèm
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>
                  3. Tử vong/ thương tật vĩnh viễn do tai nạn
                </td>
                <td style={{ textAlign: "center", fontWeight: "bold" }}>
                  {Helper._formatMoney(Benefits?.qlbosung2)}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  3.1 Tử vong/ thương tật toàn bộ vĩnh viễn
                </td>
                <td style={{ textAlign: "center" }}>100% số tiền BH</td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  3.2 Thương tật bộ phận vĩnh viễn
                </td>
                <td style={{ textAlign: "center" }}>
                  Chi trả theo bảng tỷ lệ thương tật đính kèm
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>
                  4. Chi phí y tế do tai nạn
                </td>
                <td style={{ textAlign: "center", fontWeight: "bold" }}>
                  {Helper._formatMoney(Benefits?.qlbosung3)}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: 12 }}>
                  Chi phí y tế là các chi phí phát sinh để điều trị thương tật
                  thân thể do tai nạn theo chỉ định của bác sĩ điều trị bao gồm
                  chi phí vận chuyển cấp cứu (không bao gồm vận chuyển bằng
                  phương tiện máy bay)
                </td>
                <td style={{ textAlign: "center" }}>
                  {Helper._formatMoney(Benefits?.qlbosung3)}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalBenifits;
