import React, { useEffect, useState, useContext, useCallback } from "react";
import { AuthContext } from "./../index";
import { Animated } from "react-animated-css";
import { Button, Form, Input, Select, Radio, Checkbox } from "antd";
import Idate from "./../../../assets/suc-khoe-vang/idate.png";
import Ivat from "./../../../assets/suc-khoe-vang/ivat.png";
import IvatTrue from "./../../../assets/suc-khoe-vang/ivattrue.png";
import Themnguoi from "./../../../assets/suc-khoe-vang/themnguoi.png";
import Iedit from "./../../../assets/suc-khoe-vang/editmau.png";
import Idelete from "./../../../assets/suc-khoe-vang/idelete.png";
import ModalVat from "./../modal/modalvat";
import ModalQuestion from "./../modal/modalQuestion";
import ModalAddAndEditPeople from "./../modal/modalAddAndEditPeople";
import moment from "moment";
import DatePicker from "../modal/DatePicker";
import Helper from "./../../../extra/helper";

const BuyerInfo = () => {
  const {
    goStep,
    setDataIsu,
    dataIsu,
    listIsu,
    setInsuredPerson,
    ActionList,
    indexEdit,
    setIndexEdit,
    _render_quan_he,
    setListIsu,
    MessageErr,
    fees,
    vat,
    setVAT
  } = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [datePicker, setDatePicker] = useState({});
  const [formData, setFormData] = useState({});
  const [openVat, setOpenVat] = useState(false);
  const [openQuestion, setOpenQuestion] = useState(false);
  const [openAddAndEdit, setOpenAddAndEdit] = useState(false);
  const { Option } = Select;

  useEffect(async () => {
    window.scrollTo(0, 0);
    if (dataIsu) {
      form.setFieldsValue(dataIsu);
      setFormData(dataIsu);
    }
  }, []);

  useEffect(async () => {
    setMuacho();
  }, [listIsu]);

  const setMuacho = () => {
    const lengthFilterSelf = listIsu.filter(
      (item) => item.relationshipWithBuyerName === 1
    ).length;
    const lengthFilterPeople = listIsu.filter(
      (item) => item.relationshipWithBuyerName !== 1
    ).length;
    if (lengthFilterSelf > 0 && lengthFilterPeople > 0) {
      form.setFieldsValue({ muacho: ["banthan", "nguoithan"] });
    } else if (lengthFilterSelf > 0) {
      form.setFieldsValue({ muacho: ["banthan"] });
    } else if (lengthFilterPeople > 0) {
      form.setFieldsValue({ muacho: ["nguoithan"] });
    } else {
      form.setFieldsValue({ muacho: "" });
    }
  };

  const onFinish = useCallback(async () => {
    form
      .validateFields()
      .then((values) => {
        const vat = localStorage.getItem("vatskv");
        localStorage.setItem(
          "dataIsuskv",
          JSON.stringify({ ...dataIsu, ...values })
        );
        if (values?.vat === 1) {
          setDataIsu({ ...dataIsu, ...values });
          goStep(4);
        } else {
          if (vat) {
            setDataIsu({ ...dataIsu, ...values });
            goStep(4);
          } else {
            MessageErr("Vui lòng bổ sung thông tin xuất hoá đơn!");
          }
        }
      })
      .catch((errorfields) => {});
  }, [form]);

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
    if (listIsu[0]?.relationshipWithBuyerName === 1 && name !== "vat") {
      ActionList("delete", 0, "");
    }
  };

  const handCancel = () => {
    setOpenVat(false);
    setOpenQuestion(false);
    setOpenAddAndEdit(false);
    setIndexEdit(null);
    setListIsu([...listIsu]);
  };

  const Add = () => {
    form
      .validateFields()
      .then((values) => {
        setDataIsu({ ...dataIsu, ...values });
        localStorage.setItem(
          "dataIsuskv",
          JSON.stringify({ ...dataIsu, ...values })
        );
        setInsuredPerson({
          insuredName: values.buyerName,
          insuredPrivateId: values.buyerPrivateId,
          insuredGender: values.buyerGender,
          insuredPhone: values.buyerPhone,
          insuredEmail: values.buyerEmail,
          insuredDob: values.buyerDob,
          relationshipWithBuyerName: 1,
          permanentAddress: values.address,
        });
        setOpenQuestion(true);
      })
      .catch((errorfields) => {
        setMuacho();
      });
  };
  return (
    <>
      {openAddAndEdit && (
        <ModalAddAndEditPeople
          open={openAddAndEdit}
          handleCancel={handCancel}
        />
      )}
      {openQuestion && (
        <ModalQuestion
          indexEdit={indexEdit}
          open={openQuestion}
          handleCancel={handCancel}
        />
      )}
      <ModalVat isModalVisible={openVat} handleCancel={handCancel} />
      {datePicker.open && (
        <DatePicker
          onConfirm={(data) => {
            form.setFieldsValue({
              buyerDob: data,
            });
            onChangForm({ data: data, name: "buyerDob" });
          }}
          max={moment().subtract(16, "y").toDate()}
          min={moment().subtract(65, "y").add(0, "days").toDate()}
          visible={datePicker.open}
          close={() => {
            setDatePicker({ ...datePicker, open: false });
          }}
          value={formData.buyerDob}
          title={"Ngày sinh"}
        />
      )}
      <Form
        style={{ fontSize: 14, color: "#343A40" }}
        ref={formRef}
        form={form}
        onFinish={onFinish}
        name="step3skv"
        scrollToFirstError
      >
        <div style={{ padding: 10 }}>
          <div style={css.title}>Thông tin người mua bảo hiểm</div>
          <div style={{ marginTop: 10 }}>
            <div>
              Họ và tên<span style={{ color: "red" }}>*</span>
            </div>
            <Form.Item
              name="buyerName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập thông tin",
                },
              ]}
              style={css.FormItemsHolder}
            >
              <Input
                onChange={(e) => {
                  onChangForm({ data: e.target.value, name: "buyerName" });
                }}
                autoComplete="off"
                style={{ ...css.input, textTransform: "capitalize" }}
                placeholder="Nhập họ và tên"
              />
            </Form.Item>
          </div>
          <div style={{ marginTop: 10 }}>
            <div>
              Ngày sinh<span style={{ color: "red" }}>*</span>
            </div>
            <Form.Item
              name="buyerDob"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập thông tin",
                },
              ]}
              style={css.FormItemsHolder}
            >
              <Input
                autoComplete="off"
                suffix={
                  <img style={{ width: 18 }} src={Idate} alt="Global Care" />
                }
                readOnly
                style={{ ...css.input }}
                placeholder="DD/MM/YYYY"
                onClick={() => {
                  setDatePicker({ ...datePicker, open: true });
                }}
              />
            </Form.Item>
          </div>
          <div style={{ marginTop: 10 }}>
            <div>
              Số CMND/CCCD/Số định danh cá nhân
              <span style={{ color: "red" }}>*</span>
            </div>
            <Form.Item
              name="buyerPrivateId"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập thông tin",
                },
                {
                  message:
                    "Vui lòng nhập CMND/CCCD 9 số hoặc 12 số, không bao gồm ký tự đặc biệt.",
                  pattern: /^[A-Z][0-9]{7}$|^[0-9]{9}$|^[0-9]{12}$/,
                },
              ]}
              style={css.FormItemsHolder}
            >
              <Input
                onChange={(e) => {
                  onChangForm({ data: e.target.value, name: "buyerPrivateId" });
                }}
                autoComplete="off"
                style={{ ...css.input }}
                placeholder="Nhập CMND/CCCD/Hộ chiếu "
              />
            </Form.Item>
          </div>
          <div style={{ marginTop: 10 }}>
            <div>
              Giới tính
              <span style={{ color: "red" }}>*</span>
            </div>
            <Form.Item
              name="buyerGender"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn và nhập thông tin",
                },
              ]}
              style={css.FormItemsHolder}
            >
              <Select
                onChange={(e) => {
                  onChangForm({ data: e, name: "buyerGender" });
                }}
                allowClear={true}
                className="custom-select-antd-no-padding-bhyt"
                placeholder="Chọn giới tính"
              >
                <Option value={1}>Nam</Option>
                <Option value={2}>Nữ</Option>
              </Select>
            </Form.Item>
          </div>
          <div style={{ marginTop: 10 }}>
            <div>
              Số điện thoại
              <span style={{ color: "red" }}>*</span>
            </div>
            <Form.Item
              name="buyerPhone"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập thông tin",
                },
                {
                  message:
                    "Vui lòng nhập SĐT định dạng số, không bao gồm ký tự đặc biệt.",
                  pattern: /((09|03|07|08|05)+([0-9]{8})\b)/,
                },
              ]}
              style={css.FormItemsHolder}
            >
              <Input
                onChange={(e) => {
                  onChangForm({ data: e.target.value, name: "buyerPhone" });
                }}
                autoComplete="off"
                style={{ ...css.input }}
                placeholder="Nhập số điện thoại"
              />
            </Form.Item>
          </div>
          <div style={{ marginTop: 10 }}>
            <div>
              Email
              <span style={{ color: "red" }}>*</span>
            </div>
            <Form.Item
              name="buyerEmail"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập thông tin",
                },
                {
                  message: "Email không hợp lệ",
                  pattern: /\S+@\S+\.\S+/,
                },
              ]}
              style={css.FormItemsHolder}
            >
              <Input
                onChange={(e) => {
                  onChangForm({ data: e.target.value, name: "buyerEmail" });
                }}
                autoComplete="off"
                style={{ ...css.input }}
                placeholder="Nhập email"
              />
            </Form.Item>
            <div style={{ fontSize: 12, color: "#FB7800" }}>
              Nhập email để nhận giấy chứng nhận bảo hiểm
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <div>
              Địa chỉ thường trú
              <span style={{ color: "red" }}>*</span>
            </div>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập thông tin",
                },
              ]}
              style={css.FormItemsHolder}
            >
              <Input
                onChange={(e) => {
                  onChangForm({ data: e.target.value, name: "address" });
                }}
                autoComplete="off"
                style={{ ...css.input }}
                placeholder="Nhập địa chỉ thường trú"
              />
            </Form.Item>
          </div>
        </div>
        <div style={css.driver}></div>
        <div style={{ padding: 10 }}>
          <div style={css.title}>Thông tin xuất hoá đơn</div>
          <div style={{ marginTop: 10 }}>
            <div>Lựa chọn xuất hóa đơn VAT?</div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập thông tin",
                },
              ]}
              name="vat"
              style={css.FormItemsHolder}
            >
              <Radio.Group
                onChange={(e) => {
                  onChangForm({ data: e.target.value, name: "vat" });
                  if (e.target.value === 1) {
                    localStorage.removeItem("vatskv");
                    setVAT({});
                  }
                }}
              >
                <Radio value={1}>Không</Radio>
                <Radio value={2}>Có</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div
            style={{
              margintop: 10,
              background: "#F8F9F9",
              padding: 10,
              borderRadius: 4,
              display: formData?.vat === 2 ? "" : "none",
            }}
          >
            <div style={{ marginBottom: 10, display: vat?.name ? "none" : "" }}>
              Bạn cần bổ sung thông tin hóa đơn trước khi xuất hóa đơn.
            </div>
            <div
              style={{
                padding: 7,
                color:vat?.name ? '#009B4B':"#F58220",
                fontWeight: 600,
                border: vat?.name ? "1px solid #009B4B" : "1px solid #F58220",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 4,
              }}
              onClick={() => setOpenVat(true)}
            >
              <img
                style={{ width: 18, marginRight: 5 }}
                src={vat?.name ?IvatTrue :Ivat}
                alt="Global Care"
              />
              {vat?.name ?'Thông tin hoá đơn':'Bổ sung thông tin hóa đơn'}
            </div>
          </div>
        </div>
        <div style={css.driver}></div>
        <div style={{ padding: 10 }}>
          <div style={css.title}>Bạn mua bảo hiểm cho ai?</div>
          <div style={{ marginTop: 10 }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn người được bảo hiểm",
                },
              ]}
              name="muacho"
              style={css.FormItemsHolder}
            >
              <Checkbox.Group>
                <Checkbox
                  onClick={() => {
                    setTimeout(() => {
                      if (listIsu[0]?.relationshipWithBuyerName === 1) {
                        ActionList("delete", 0, "");
                        return;
                      }
                      Add();
                    }, 100);
                  }}
                  value="banthan"
                >
                  Cho bản thân
                </Checkbox>
                <Checkbox
                  onClick={() => {
                    if (
                      listIsu.filter(
                        (item) => item.relationshipWithBuyerName !== 1
                      ).length > 0
                    ) {
                      setListIsu(
                        listIsu.filter(
                          (item) => item.relationshipWithBuyerName === 1
                        )
                      );
                      return;
                    }
                    setTimeout(() => {
                      setOpenAddAndEdit(true);
                    }, 100);
                  }}
                  value="nguoithan"
                >
                  Cho người thân
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div
            style={{
              marginTop: 10,
              display: listIsu.length > 0 ? "" : "none",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <b>Danh sách người được BH</b>
              </div>
              <div
                onClick={() => setOpenAddAndEdit(true)}
                style={{
                  fontWeight: 500,
                  color: "#009B4B",
                  display: "flex",
                  alignItems: "center",
                  display:
                    listIsu.filter(
                      (item) => item.relationshipWithBuyerName !== 1
                    ).length > 0
                      ? ""
                      : "none",
                }}
              >
                Thêm
                <img
                  style={{ width: 18, marginLeft: 5 }}
                  src={Themnguoi}
                  alt="Global Care"
                />
              </div>
            </div>
          </div>
          {/* Danh sách người được BH */}
          {listIsu.map((data, index) => (
            <div key={index} style={css.itemPeople}>
              <div
                style={{
                  ...css.divFlex,
                  width: "100%",
                }}
              >
                <div>
                  <div
                    style={{
                      color: "#009B4B",
                      fontWeight: 500,
                      textTransform: "capitalize",
                    }}
                  >
                    {data?.insuredName}
                  </div>
                  <div style={{ fontSize: 10, color: "#646D69" }}>
                    {_render_quan_he(data?.relationshipWithBuyerName)} -{" "}
                    {data?.insuredDob}
                  </div>
                </div>
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontSize: 10, color: "#646D69" }}>
                    Phí bảo hiểm
                  </div>
                  <div style={{ fontWeight: 500 }}>
                    {Helper._formatMoney(fees?.fees)} VNĐ/
                    {dataIsu?.period === 1 ? "tháng" : "năm"}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "10%",
                  justifyContent: "flex-end",
                }}
              >
                {data?.relationshipWithBuyerName !== 1 && (
                  <div>
                    <img
                      onClick={() => {
                        setIndexEdit(index);
                        setOpenAddAndEdit(true);
                      }}
                      style={{ width: 18, marginLeft: 10 }}
                      src={Iedit}
                      alt="Global Care"
                    />
                  </div>
                )}
                <div>
                  <img
                    onClick={() => {
                      ActionList("delete", index, "");
                    }}
                    style={{ marginLeft: 10, width: 13 }}
                    src={Idelete}
                    alt="Global Care"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <Animated
          animationIn="fadeInUp"
          animationInDuration={500}
          isVisible={true}
          style={{ width: "100%", position: "sticky", bottom: 0 }}
        >
        <div style={css.boxbtn}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 0px",
            }}
          >
            <span>Tổng tiền thanh toán</span>
            <span style={{ color: "#009B4B" }}>
              <b>
                {Helper._formatMoney(
                  fees?.fees * (listIsu.length ? listIsu.length : 0)
                )}{" "}
                VNĐ
              </b>
            </span>
          </div>
          <div style={css.divFlex}>
            <Button
              onClick={() => goStep(2)}
              style={css.btnBack}
              type="#009B4B"
            >
              Quay lại
            </Button>
            <Button htmlType="submit" style={css.btnNext} type="#009B4B">
              Tiếp tục
            </Button>
          </div>
        </div>
        </Animated>
      </Form>
    </>
  );
};

const css = {
  title: {
    fontSize: 16,
    color: "#009B4B",
    fontWeight: 600,
  },
  FormItemsHolder: {
    width: "100%",
    borderRadius: 8,
    margin: "5px 0px",
    padding: 0,
  },
  input: { borderRadius: 8, padding: 10, fontSize: 14, color: "#343A40" },
  driver: {
    width: "100%",
    height: 5,
    background: "#0000001A",
  },
  btnBack: {
    color: "#009B4B",
    background: "#FFF",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
    border: "1px solid #009B4B",
  },
  btnNext: {
    color: "#FFF",
    background: "#009B4B",
    fontWeight: "bold",
    width: "48%",
    height: "44px",
    borderRadius: 8,
  },
  boxbtn: {
    position: "sticky",
    bottom: 0,
    height: 96,
    background: "#fff",
    borderRadius: "16px 16px 0px 0px",
    boxShadow: "0px -4px 8px 0px #0000001A",
    padding: "0px 10px",
    marginTop: 10,
  },
  itemPeople: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 7,
    borderRadius: 5,
    background: "#F8F9FA",
  },
  divFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

export default BuyerInfo;
