import React, { useEffect, useState } from "react";
import { message } from "antd";
import "./style.css";
import "antd/dist/antd.css";
// Bước 1 gới thiệu
import Introduce from "./page/1-introduce";
// Bước 2 chọn quyền lợi bảo hiểm
import Benefits from "./page/2-InsuranceBenefits";
// Bước 3 thông tin người mua và thêm người được BH
import BuyerInfo from "./page/3-buyerInformation";
// Bước 4 xem lại thông tin và tạo đơn
import Create from "./page/4-Create";
// Bước 1 luồng mua lại
import Search from "./page/search-orders";
// Bước 2 luồng mua lại
import CreateBuybackOrder from "./page/Create-buyback-order";
import History from "./../../routes/history";
import helper_center from "./../../extra/helper";
import Service from "./../../extra/getAPI";
export const AuthContext = React.createContext();

const Index = () => {
  const [step, setStep] = useState(1);
  const platform = helper_center.getParamUrlByKey("platform");
  //Thông tin bảo hiểm từ bước 1 đến bước 4
  const [dataIsu, setDataIsu] = useState({});
  // Thông tin người được bảo hiểm
  const [insuredPerson, setInsuredPerson] = useState({});
  // Danh sách người được bảo hiểm
  const [listIsu, setListIsu] = useState([]);
  // Data thông tin bảo hiểm của luồng mua lại
  const [dataIsuBuyBack, setDataIsuBuyBack] = useState({});
  // Danh sách người được bảo hiểm cho luồng mua lại
  const [acquisitionList, setAcquisitionList] = useState([]);

  const [indexEdit, setIndexEdit] = useState(null);
  // link chử ký
  const [linkIMG, setLinkIMG] = useState({});
  // Phí bảo hiểm 1 người
  const [fees, setFees] = useState({});
  const [vat, setVAT] = useState({});

  const goStep = (step) => {
    setStep(step);
  };

  const PageView = () => {
    switch (step) {
      case 1:
        return <Introduce />;
      case 2:
        return <Benefits />;
      case 3:
        return <BuyerInfo />;
      case 4:
        return <Create />;
      case 5:
        return <Search />;
      case 6:
        return <CreateBuybackOrder />;
    }
  };

  const _render_quan_he = (value) => {
    switch (value) {
      case 1:
        return "Bản thân";
      case 2:
        return "Vợ/Chồng";
      case 3:
        return "Con hợp pháp";
      case 4:
        return "Ba ruột/ Mẹ ruột";
      case 5:
        return "Anh/Chị/Em ruột";
      default:
        break;
    }
  };

  // Thêm xoá sửa danh sách người bao hiểm
  const ActionList = (action, index, value) => {
    let listIsured = listIsu;
    switch (action) {
      case "unshift":
        listIsured.unshift({ ...value });
        setListIsu([...listIsured]);
        localStorage.setItem("listInsuredSKV", JSON.stringify(listIsured));
        MessageSucc("Thêm người được bảo hiểm thành công!");
        window.scrollTo(2000, 2000);
        break;
      case "delete":
        listIsured.splice(index, 1);
        setListIsu([...listIsured]);
        localStorage.setItem("listInsuredSKV", JSON.stringify(listIsured));
        break;
      case "add":
        listIsured.push({ ...value });
        setListIsu([...listIsured]);
        localStorage.setItem("listInsuredSKV", JSON.stringify(listIsured));
        MessageSucc("Thêm người được bảo hiểm thành công!");
        window.scrollTo(2000, 2000);
        break;
      case "edit":
        listIsured[index] = { ...value };
        setListIsu([...listIsured]);
        localStorage.setItem("listInsuredSKV", JSON.stringify(listIsured));
        MessageSucc("Cập nhật thành công!");
        window.scrollTo(2000, 2000);
        break;
    }
  };

  // Lấy phí bảo hiểm
  const getFees = (formData) => {
    let data = {
      primaryAmount: formData?.primaryAmount,
      additionalAmount_1: formData?.checkqlmot?.[0] ? formData?.qlbosung1 : 0,
      additionalAmount_2: formData?.checkqlhai?.[0] ? formData?.qlbosung2 : 0,
      additionalAmount_3: formData?.checkqlba?.[0] ? formData?.qlbosung3 : 0,
      period: formData?.period,
    };
    data = JSON.stringify(data);
    Service.apicall("POST", `/api/v1/product/health-golden/fees`, data)
      .then((res) => {
        let data = res?.data;
        setFees(data?.result);
      })
      .catch((err) => {
        // MessageErr("Lấy phí thất bại.");
      });
  };

  const OpenLink = (link) => {
    if (platform === "app") {
      // History.replace(`/gotoBrownser?link=${link}`);
      window.ReactNativeWebView.postMessage(`/gotoBrownser?link=${link}`);
    }else{
      window.open(link, "_blank");
    }
  };

  const uploadG2 = (file, name, nameFile) => {
    MessageWarning("Chữ ký đang được xử lý!");
    var form = new FormData();
    const timestamp = +new Date();
    form.append("storage", "s3");
    form.append("file", file);
    form.append("path", `/user/${nameFile}/${timestamp}-${name}`);
    Service.apicall_upload_G2("POST", `/upload`, form)
      .then((res) => {
        let data = res?.data;
        if (data?.complete) {
          MessageSucc("Ký tên thành công.");
          setLinkIMG({
            ...linkIMG,
            signature: data?.link,
          });
        } else {
          MessageErr("Ký tên lỗi vui lòng ký lại.");
        }
      })
      .catch((err) => {
        MessageErr("Ký tên lỗi vui lòng ký lại.");
      });
  };

  // Thông báo lỗi dủng chung cho các page
  const MessageErr = (content) => {
    message.error(
      {
        content: content,
        style: {
          marginTop: "25px",
        },
      },
      15
    );
  };

  // Thông báo lỗi dủng chung cho các page
  const MessageWarning = (content) => {
    message.warning(
      {
        content: content,
        style: {
          marginTop: "25px",
        },
      },
      15
    );
  };

  // Thông báo thành công dủng chung cho các page
  const MessageSucc = (content) => {
    message.success(
      {
        content: content,
        style: {
          marginTop: "25px",
        },
      },
      15
    );
  };

  return (
    <AuthContext.Provider
      value={{
        MessageErr,
        OpenLink,
        goStep,
        dataIsu,
        setDataIsu,
        ActionList,
        listIsu,
        setListIsu,
        insuredPerson,
        setInsuredPerson,
        indexEdit,
        setIndexEdit,
        _render_quan_he,
        linkIMG,
        setLinkIMG,
        uploadG2,
        fees,
        setFees,
        getFees,
        acquisitionList,
        setAcquisitionList,
        dataIsuBuyBack,
        setDataIsuBuyBack,
        vat,
        setVAT,
      }}
    >
      <div style={{ maxWidth: 800, margin: "0px auto", color: "#343A40" }}>
        {PageView()}
      </div>
    </AuthContext.Provider>
  );
};

export default Index;
